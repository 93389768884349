import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Utilities_OperationService } from './Utilities.operation.service';


@Injectable({ providedIn: 'root' })
export class Ultraship_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.Utilities = this.injector.get(Utilities_OperationService);
  }

    public Utilities: Utilities_OperationService;
  public Ultraship: Ultraship_OperationService = this;

  public Disable_Navigation_Integrations_Ultraship_Manager = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Disable_Navigation_Integrations_Ultraship_Manager');
    }
  }

  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/Ultraship/operations/${operationName}/isauthorized`, null);
  }
}
