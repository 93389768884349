import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { app_custom_inventory_location_gridComponent } from './app.custom_inventory_location_grid.component';
import { app_custom_inventory_licenseplate_gridComponent } from './app.custom_inventory_licenseplate_grid.component';
import { app_custom_inventory_lot_gridComponent } from './app.custom_inventory_lot_grid.component';
import { app_custom_inventory_material_gridComponent } from './app.custom_inventory_material_grid.component';
import { FootPrintManager_inventory_licenseplate_snapshot_gridComponent } from './FootPrintManager.inventory_licenseplate_snapshot_grid.component';
import { FootPrintManager_inventory_lot_snapshot_gridComponent } from './FootPrintManager.inventory_lot_snapshot_grid.component';
import { FootPrintManager_inventory_material_snapshot_gridComponent } from './FootPrintManager.inventory_material_snapshot_grid.component';
import { FootPrintManager_inventory_tasks_gridComponent } from './FootPrintManager.inventory_tasks_grid.component';
import { Inventory_total_available_amount_widgetComponent } from './Inventory.total_available_amount_widget.component';
import { Inventory_total_packaged_amount_widgetComponent } from './Inventory.total_packaged_amount_widget.component';
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Materials_materials_by_project_dd_multiComponent } from './Materials.materials_by_project_dd_multi.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Inventory_inventory_aggregation_dd_singleComponent } from './Inventory.inventory_aggregation_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => app_custom_inventory_location_gridComponent),
    forwardRef(() => app_custom_inventory_licenseplate_gridComponent),
    forwardRef(() => app_custom_inventory_lot_gridComponent),
    forwardRef(() => app_custom_inventory_material_gridComponent),
    forwardRef(() => FootPrintManager_inventory_licenseplate_snapshot_gridComponent),
    forwardRef(() => FootPrintManager_inventory_lot_snapshot_gridComponent),
    forwardRef(() => FootPrintManager_inventory_material_snapshot_gridComponent),
    forwardRef(() => FootPrintManager_inventory_tasks_gridComponent),
    forwardRef(() => Inventory_total_available_amount_widgetComponent),
    forwardRef(() => Inventory_total_packaged_amount_widgetComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Materials_materials_by_project_dd_multiComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Inventory_inventory_aggregation_dd_singleComponent),
  ],
  selector: 'app-custom_inventory_hub',
  templateUrl: './app.custom_inventory_hub.component.html'
})
export class app_custom_inventory_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { ownerId?: number, projectId?: number, warehouseId?: number } = { ownerId: null, projectId: null, warehouseId: null };
  //#region Inputs
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    materials: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vendorReference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    ownerReference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    inventory_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    inventory_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      create_inventory: new ToolModel(new ButtonModel('create_inventory', new ButtonStyles(null, null), false, 'Create inventory', 'ms-Icon ms-Icon--Add', null)
    ),
      import_inventory: new ToolModel(new ButtonModel('import_inventory', new ButtonStyles(null, null), false, 'Import inventory', 'ms-Icon ms-Icon--ExcelDocument', null)
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      Manager: new ToolModel(new SplitButtonModel<{ manage_container_types: ButtonModel, manage_reason_codes: ButtonModel, inventory_snapshot_rules: ButtonModel }>(
        'Manager',
        new ButtonStyles(null, null),
        false,
        'Manage',
        'ms-Icon ms-Icon--SettingsAdd',
        null,
        [
          new ButtonModel('manage_container_types', new ButtonStyles(null, null), false, 'Container types', 'ms-Icon ms-Icon--GiftboxOpen'),
          new ButtonModel('manage_reason_codes', new ButtonStyles(null, null), false, 'Reason codes', 'ms-Icon ms-Icon--ReceiptCheck'),
          new ButtonModel('inventory_snapshot_rules', new ButtonStyles(null, null), false, 'Inventory snapshot rules', 'ms-Icon ms-Icon--BusinessRule')
        ])
    )
  };

  actionbar = {
  };

 filters = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Owner', false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Project', false),
    materials: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['materials'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Materials', false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', false),
    vendorReference: new FieldModel(new TextBoxModel(this.formGroup.controls['vendorReference'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Vendor reference', false),
    ownerReference: new FieldModel(new TextBoxModel(this.formGroup.controls['ownerReference'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Owner reference', false),
    inventory_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['inventory_type'] as DatexFormControl, 
  ESelectBoxType.chips, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Inventory type', false),
    inventory_date: new FieldModel(new DateBoxModel(this.formGroup.controls['inventory_date'] as DatexFormControl, null, false, 'MM/DD/YYYY', 'date', null)
, new ControlContainerStyles(null, null), 'Inventory date', false),
  };


  filtersets = {
  newGroup1: new FieldsetModel('Filters', false, true, false),
  newGroup2: new FieldsetModel('', true, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      inventory_by_location: new TabItemModel(
        this.rootTabGroup, 
        'Inventory', 
        ),
      inventory_by_lp: new TabItemModel(
        this.rootTabGroup, 
        'Inventory', 
        ),
      inventory_by_lot: new TabItemModel(
        this.rootTabGroup, 
        'Inventory', 
        ),
      inventory_by_material: new TabItemModel(
        this.rootTabGroup, 
        'Inventory', 
        ),
      inventory_by_licenseplate_snapshot: new TabItemModel(
        this.rootTabGroup, 
        'Inventory', 
        ),
      inventory_by_lot_snapshot: new TabItemModel(
        this.rootTabGroup, 
        'Inventory', 
        ),
      inventory_by_material_snapshot: new TabItemModel(
        this.rootTabGroup, 
        'Inventory', 
        ),
      tasks: new TabItemModel(
        this.rootTabGroup, 
        'Activity', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_inventory_by_location_custom_inventory_location_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_location_custom_inventory_location_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_location_custom_inventory_location_grid_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_inventory_by_location_custom_inventory_location_grid_inParams_materialIds: number[];
    get $tabs_inventory_by_location_custom_inventory_location_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inventory_by_location_custom_inventory_location_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_inventory_by_location_custom_inventory_location_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_inventory_by_location_custom_inventory_location_grid_inParams_materialIds;
    }
  
    get $tabs_inventory_by_location_custom_inventory_location_grid_inParams_vendorReference(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.vendorReference.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_location_custom_inventory_location_grid_inParams_ownerReference(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.ownerReference.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_lp_custom_inventory_licenseplate_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_lp_custom_inventory_licenseplate_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_lp_custom_inventory_licenseplate_grid_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_inventory_by_lp_custom_inventory_licenseplate_grid_inParams_materialIds: number[];
    get $tabs_inventory_by_lp_custom_inventory_licenseplate_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inventory_by_lp_custom_inventory_licenseplate_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_inventory_by_lp_custom_inventory_licenseplate_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_inventory_by_lp_custom_inventory_licenseplate_grid_inParams_materialIds;
    }
  
    get $tabs_inventory_by_lp_custom_inventory_licenseplate_grid_inParams_vendorReference(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.vendorReference.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_lp_custom_inventory_licenseplate_grid_inParams_ownerReference(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.ownerReference.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_lot_custom_inventory_lot_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_lot_custom_inventory_lot_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_lot_custom_inventory_lot_grid_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_inventory_by_lot_custom_inventory_lot_grid_inParams_materialIds: number[];
    get $tabs_inventory_by_lot_custom_inventory_lot_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inventory_by_lot_custom_inventory_lot_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_inventory_by_lot_custom_inventory_lot_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_inventory_by_lot_custom_inventory_lot_grid_inParams_materialIds;
    }
  
    get $tabs_inventory_by_lot_custom_inventory_lot_grid_inParams_vendorReference(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.vendorReference.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_lot_custom_inventory_lot_grid_inParams_ownerReference(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.ownerReference.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_material_custom_inventory_material_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_material_custom_inventory_material_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_material_custom_inventory_material_grid_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_inventory_by_material_custom_inventory_material_grid_inParams_materialIds: number[];
    get $tabs_inventory_by_material_custom_inventory_material_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inventory_by_material_custom_inventory_material_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_inventory_by_material_custom_inventory_material_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_inventory_by_material_custom_inventory_material_grid_inParams_materialIds;
    }
  
    get $tabs_inventory_by_material_custom_inventory_material_grid_inParams_vendorReference(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.vendorReference.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_material_custom_inventory_material_grid_inParams_ownerReference(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.ownerReference.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_licenseplate_snapshot_inventory_licenseplate_snapshot_grid_inParams_captureDate(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.inventory_date.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_licenseplate_snapshot_inventory_licenseplate_snapshot_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_licenseplate_snapshot_inventory_licenseplate_snapshot_grid_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_inventory_by_licenseplate_snapshot_inventory_licenseplate_snapshot_grid_inParams_materialIds: number[];
    get $tabs_inventory_by_licenseplate_snapshot_inventory_licenseplate_snapshot_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inventory_by_licenseplate_snapshot_inventory_licenseplate_snapshot_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_inventory_by_licenseplate_snapshot_inventory_licenseplate_snapshot_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_inventory_by_licenseplate_snapshot_inventory_licenseplate_snapshot_grid_inParams_materialIds;
    }
  
    get $tabs_inventory_by_lot_snapshot_inventory_lot_snapshot_grid_inParams_captureDate(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.inventory_date.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_lot_snapshot_inventory_lot_snapshot_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_lot_snapshot_inventory_lot_snapshot_grid_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_inventory_by_lot_snapshot_inventory_lot_snapshot_grid_inParams_materialIds: number[];
    get $tabs_inventory_by_lot_snapshot_inventory_lot_snapshot_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inventory_by_lot_snapshot_inventory_lot_snapshot_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_inventory_by_lot_snapshot_inventory_lot_snapshot_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_inventory_by_lot_snapshot_inventory_lot_snapshot_grid_inParams_materialIds;
    }
  
    get $tabs_inventory_by_material_snapshot_inventory_material_snapshot_grid_inParams_captureDate(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.inventory_date.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_material_snapshot_inventory_material_snapshot_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_material_snapshot_inventory_material_snapshot_grid_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_inventory_by_material_snapshot_inventory_material_snapshot_grid_inParams_materialIds: number[];
    get $tabs_inventory_by_material_snapshot_inventory_material_snapshot_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inventory_by_material_snapshot_inventory_material_snapshot_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_inventory_by_material_snapshot_inventory_material_snapshot_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_inventory_by_material_snapshot_inventory_material_snapshot_grid_inParams_materialIds;
    }
  
    cacheValueFor_$tabs_tasks_inventory_tasks_grid_inParams_materialIds: number[];
    get $tabs_tasks_inventory_tasks_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_tasks_inventory_tasks_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_tasks_inventory_tasks_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_tasks_inventory_tasks_grid_inParams_materialIds;
    }
  
    get $tabs_tasks_inventory_tasks_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $tabs_tasks_inventory_tasks_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_tasks_inventory_tasks_grid_inParams_warehouseIds: number[];
    get $tabs_tasks_inventory_tasks_grid_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $utils.isDefined($hub.filters.warehouse.control.value) ? [$hub.filters.warehouse.control.value] : null;
      
      if(!isEqual(this.cacheValueFor_$tabs_tasks_inventory_tasks_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_tasks_inventory_tasks_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_tasks_inventory_tasks_grid_inParams_warehouseIds;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_inventory_by_location', { read: app_custom_inventory_location_gridComponent }) $tabs_inventory_by_location: app_custom_inventory_location_gridComponent;
      @ViewChild('$tabs_inventory_by_lp', { read: app_custom_inventory_licenseplate_gridComponent }) $tabs_inventory_by_lp: app_custom_inventory_licenseplate_gridComponent;
      @ViewChild('$tabs_inventory_by_lot', { read: app_custom_inventory_lot_gridComponent }) $tabs_inventory_by_lot: app_custom_inventory_lot_gridComponent;
      @ViewChild('$tabs_inventory_by_material', { read: app_custom_inventory_material_gridComponent }) $tabs_inventory_by_material: app_custom_inventory_material_gridComponent;
      @ViewChild('$tabs_inventory_by_licenseplate_snapshot', { read: FootPrintManager_inventory_licenseplate_snapshot_gridComponent }) $tabs_inventory_by_licenseplate_snapshot: FootPrintManager_inventory_licenseplate_snapshot_gridComponent;
      @ViewChild('$tabs_inventory_by_lot_snapshot', { read: FootPrintManager_inventory_lot_snapshot_gridComponent }) $tabs_inventory_by_lot_snapshot: FootPrintManager_inventory_lot_snapshot_gridComponent;
      @ViewChild('$tabs_inventory_by_material_snapshot', { read: FootPrintManager_inventory_material_snapshot_gridComponent }) $tabs_inventory_by_material_snapshot: FootPrintManager_inventory_material_snapshot_gridComponent;
      @ViewChild('$tabs_tasks', { read: FootPrintManager_inventory_tasks_gridComponent }) $tabs_tasks: FootPrintManager_inventory_tasks_gridComponent;
    //#endregion tabs children
    widgets = {
      total_available_amount_widget: new WidgetModel(),
      total_packaged_amount_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_total_available_amount_widget_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $widgets_total_available_amount_widget_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$widgets_total_available_amount_widget_inParams_materialIds: number[];
    get $widgets_total_available_amount_widget_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_total_available_amount_widget_inParams_materialIds, expr)) {
        this.cacheValueFor_$widgets_total_available_amount_widget_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$widgets_total_available_amount_widget_inParams_materialIds;
    }
  
    get $widgets_total_available_amount_widget_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    get $widgets_total_packaged_amount_widget_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    get $widgets_total_packaged_amount_widget_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $widgets_total_packaged_amount_widget_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$widgets_total_packaged_amount_widget_inParams_materialIds: number[];
    get $widgets_total_packaged_amount_widget_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_total_packaged_amount_widget_inParams_materialIds, expr)) {
        this.cacheValueFor_$widgets_total_packaged_amount_widget_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$widgets_total_packaged_amount_widget_inParams_materialIds;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_total_available_amount_widget', { read:  Inventory_total_available_amount_widgetComponent }) $widgets_total_available_amount_widget: Inventory_total_available_amount_widgetComponent;
      @ViewChild('$widgets_total_packaged_amount_widget', { read:  Inventory_total_packaged_amount_widgetComponent }) $widgets_total_packaged_amount_widget: Inventory_total_packaged_amount_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_owner_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  get $fields_project_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_project_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.owner.control.value;
    
    return expr;
  }



  get $fields_materials_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Inventory hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Your landing page to view inventory and all related actions across projects and or warehouses.`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: app_ShellService,
  private datasources: app_DatasourceService,
  private flows: app_FlowService,
  private reports: app_ReportService,
  private localization: app_LocalizationService,
  private operations: app_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.inventory_by_location,
      this.tabs.inventory_by_lp,
      this.tabs.inventory_by_lot,
      this.tabs.inventory_by_material,
      this.tabs.inventory_by_licenseplate_snapshot,
      this.tabs.inventory_by_lot_snapshot,
      this.tabs.inventory_by_material_snapshot,
      this.tabs.tasks,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'custom_inventory_hub';
    
    const $hub = this;
    const $utils = this.utils;

    
    
    (this.filters.inventory_type.control as SelectBoxModel).reset(1);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['owner']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.formGroup
      .controls['project']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.formGroup
      .controls['inventory_type']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_inventory_type_change();
      });
    this.formGroup
      .controls['inventory_date']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_inventory_date_change();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_total_available_amount_widget') {
      if (!isNil(this.$widgets_total_available_amount_widget) && !this.widgets.total_available_amount_widget.hidden) {
        this.$widgets_total_available_amount_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_total_packaged_amount_widget') {
      if (!isNil(this.$widgets_total_packaged_amount_widget) && !this.widgets.total_packaged_amount_widget.hidden) {
        this.$widgets_total_packaged_amount_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_inventory_by_location') {
      if (!isNil(this.$tabs_inventory_by_location) && !this.tabs.inventory_by_location.hidden) {
        this.$tabs_inventory_by_location.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_inventory_by_lp') {
      if (!isNil(this.$tabs_inventory_by_lp) && !this.tabs.inventory_by_lp.hidden) {
        this.$tabs_inventory_by_lp.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_inventory_by_lot') {
      if (!isNil(this.$tabs_inventory_by_lot) && !this.tabs.inventory_by_lot.hidden) {
        this.$tabs_inventory_by_lot.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_inventory_by_material') {
      if (!isNil(this.$tabs_inventory_by_material) && !this.tabs.inventory_by_material.hidden) {
        this.$tabs_inventory_by_material.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_inventory_by_licenseplate_snapshot') {
      if (!isNil(this.$tabs_inventory_by_licenseplate_snapshot) && !this.tabs.inventory_by_licenseplate_snapshot.hidden) {
        this.$tabs_inventory_by_licenseplate_snapshot.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_inventory_by_lot_snapshot') {
      if (!isNil(this.$tabs_inventory_by_lot_snapshot) && !this.tabs.inventory_by_lot_snapshot.hidden) {
        this.$tabs_inventory_by_lot_snapshot.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_inventory_by_material_snapshot') {
      if (!isNil(this.$tabs_inventory_by_material_snapshot) && !this.tabs.inventory_by_material_snapshot.hidden) {
        this.$tabs_inventory_by_material_snapshot.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_tasks') {
      if (!isNil(this.$tabs_tasks) && !this.tabs.tasks.hidden) {
        this.$tabs_tasks.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: app_custom_inventory_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_hub.on_init');
  $hub.title = 'Inventory Hub'
  
  $hub.tabs.inventory_by_lp.hidden = true;
  $hub.tabs.inventory_by_lot.hidden = true;
  $hub.tabs.inventory_by_material.hidden = true;
  
  $hub.tabs.inventory_by_material_snapshot.hidden = true;
  $hub.tabs.inventory_by_lot_snapshot.hidden = true;
  $hub.tabs.inventory_by_licenseplate_snapshot.hidden = true;
  $hub.filters.inventory_date.hidden = true;
  
  $hub.filters.owner.control.value = $hub.inParams.ownerId;
  $hub.filters.project.control.value = $hub.inParams.projectId;
  $hub.filters.warehouse.control.value = $hub.inParams.warehouseId;
  
  
  if ((await $operations.FootPrintManager.Enable_Locations_DefaultWarehouseByActivity.isAuthorized()) && !$utils.isDefined($hub.inParams.warehouseId)) {
      let username = (await $flows.Utilities.get_username_flow({})).userName;
  
      let defaultWarehouseResult = (await $datasources.Locations.ds_get_default_warehouse_by_user.get({ userName: username })).result;
  
      $hub.filters.warehouse.control.value = $utils.isDefined(defaultWarehouseResult?.warehouseId) ? defaultWarehouseResult.warehouseId : null;
  
  }
  
  
  
  $hub.filters.inventory_date.control.value = $utils.date.now();
  
  
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $hub: app_custom_inventory_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_hub.on_owner_change');
  $hub.filters.project.control.value = null;
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $hub: app_custom_inventory_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_hub.on_project_change');
  const projectId = $hub.filters.project.control.value
  
  
  // Set Owner from Project
  if ($utils.isDefined(projectId) && !$utils.isDefined($hub.filters.owner.control.value)) {
      const project = (await $datasources.Owners.ds_get_project_by_projectId.get({
          projectId: projectId
      })).result;
     
      if ($utils.isDefined(project)) {
  
          $hub.filters.owner.control.value = project[0].OwnerId;
  
      }
  
  }
  
  }
  on_create_inventory_clicked(event = null) {
    return this.on_create_inventory_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_inventory_clickedInternal(
    $hub: app_custom_inventory_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_hub.on_create_inventory_clicked');
  
  // Initialize the first material selected
  var materialId = null;
  
  if ($utils.isDefined($hub.filters.materials.control.value)) {
      materialId = $hub.filters.materials.control.value[0];
  };
  
  
  // Initialize the first warehouse selected
  var warehouseId = null;
  
  if ($utils.isDefined($hub.filters.warehouse.control.value)) {
      warehouseId = $hub.filters.warehouse.control.value[0];
  };
  
  
  
  
  const dialogResult = await $shell.FootPrintManager.openinventory_creation_formDialog({
      ownerId: $hub.filters.owner.control.value,
      projectId: $hub.filters.project.control.value,
      warehouseId:$utils.isDefined(warehouseId) ? warehouseId : null,
      materialId: $utils.isDefined(materialId) ? materialId : null
  });
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
      $hub.refresh()
  }
  }
  on_import_inventory_clicked(event = null) {
    return this.on_import_inventory_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_import_inventory_clickedInternal(
    $hub: app_custom_inventory_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_hub.on_import_inventory_clicked');
  //$shell.ExcelInventoryImport.openexcel_inventory_import_grid();
  
  //Wavelength Component 182554: Excel inventory import performance overhaul
  $shell.ExcelInventoryImport.openexcel_inventory_import_staging_grid();
  }
  on_inventory_type_change(event = null) {
    return this.on_inventory_type_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_inventory_type_changeInternal(
    $hub: app_custom_inventory_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_hub.on_inventory_type_change');
  var inventoryType = $hub.filters.inventory_type.control.value;
  
  
  
  
  if (inventoryType == 1) {
      $hub.tabs.inventory_by_location.hidden = false;
      $hub.tabs.inventory_by_location.activate();
  
      $hub.filters.inventory_date.hidden = true;
      $hub.filters.inventory_date.control.value = $utils.date.now();
  
      $hub.tabs.inventory_by_lp.hidden = true;
      $hub.tabs.inventory_by_lot.hidden = true;
      $hub.tabs.inventory_by_material.hidden = true;
      $hub.tabs.inventory_by_material_snapshot.hidden = true;
      $hub.tabs.inventory_by_lot_snapshot.hidden = true;
      $hub.tabs.inventory_by_licenseplate_snapshot.hidden = true;
  }
  
  else if (inventoryType == 2) {
      $hub.tabs.inventory_by_lp.hidden = false;
      $hub.tabs.inventory_by_lp.activate();
  
  
      $hub.filters.inventory_date.hidden = false;
      $hub.filters.inventory_date.control.value = $utils.date.now();
  
  
      $hub.tabs.inventory_by_location.hidden = true;
      $hub.tabs.inventory_by_lot.hidden = true;
      $hub.tabs.inventory_by_material.hidden = true;
      $hub.tabs.inventory_by_material_snapshot.hidden = true;
      $hub.tabs.inventory_by_lot_snapshot.hidden = true;
      $hub.tabs.inventory_by_licenseplate_snapshot.hidden = true;
  
  
  }
  else if (inventoryType == 3) {
      $hub.tabs.inventory_by_lot.hidden = false;
      $hub.tabs.inventory_by_lot.activate();
  
  
  
      $hub.filters.inventory_date.hidden = false;
      $hub.filters.inventory_date.control.value = $utils.date.now();
  
  
      $hub.tabs.inventory_by_location.hidden = true;
      $hub.tabs.inventory_by_lp.hidden = true;
      $hub.tabs.inventory_by_material.hidden = true;
      $hub.tabs.inventory_by_material_snapshot.hidden = true;
      $hub.tabs.inventory_by_lot_snapshot.hidden = true;
      $hub.tabs.inventory_by_licenseplate_snapshot.hidden = true;
  
  }
  else if (inventoryType == 4) {
      $hub.tabs.inventory_by_material.hidden = false;
      $hub.tabs.inventory_by_material.activate();
  
  
  
      $hub.filters.inventory_date.hidden = false;
      $hub.filters.inventory_date.control.value = $utils.date.now();
  
  
      $hub.tabs.inventory_by_location.hidden = true;
      $hub.tabs.inventory_by_lp.hidden = true;
      $hub.tabs.inventory_by_lot.hidden = true;
      $hub.tabs.inventory_by_material_snapshot.hidden = true;
      $hub.tabs.inventory_by_lot_snapshot.hidden = true;
      $hub.tabs.inventory_by_licenseplate_snapshot.hidden = true;
  
  }
  }
  on_manage_container_types_clicked(event = null) {
    return this.on_manage_container_types_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manage_container_types_clickedInternal(
    $hub: app_custom_inventory_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_hub.on_manage_container_types_clicked');
  $shell.FootPrintManager.opencontainer_types_grid({});
  
  }
  on_manage_reason_codes_clicked(event = null) {
    return this.on_manage_reason_codes_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manage_reason_codes_clickedInternal(
    $hub: app_custom_inventory_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_hub.on_manage_reason_codes_clicked');
  //O.Arias - 11/21/2023
  
  $shell.FootPrintManager.openreason_codes_grid({});
  }
  on_inventory_date_change(event = null) {
    return this.on_inventory_date_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_inventory_date_changeInternal(
    $hub: app_custom_inventory_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_hub.on_inventory_date_change');
  
  
  //let setDate = $utils.date.now();
  let hubSelectedDate = $hub.filters.inventory_date.control.value;
  
  const date = new Date($utils.date.now());
  const trimmedDate = date.toISOString().split("T")[0];
  
  const hubDate = new Date(hubSelectedDate);
  const trimmedHubDate = hubDate.toISOString().split("T")[0];
  
  const inventoryType = $hub.filters.inventory_type.control.value;
  
  if (trimmedHubDate > trimmedDate || trimmedHubDate < trimmedDate) {
  
      if (inventoryType == 2) {
          $hub.tabs.inventory_by_licenseplate_snapshot.hidden = false;
          $hub.tabs.inventory_by_licenseplate_snapshot.activate()
  
          $hub.tabs.inventory_by_location.hidden = true;
          $hub.tabs.inventory_by_lot.hidden = true;
          $hub.tabs.inventory_by_lp.hidden = true;
          $hub.tabs.inventory_by_material.hidden = true;
          $hub.tabs.inventory_by_material_snapshot.hidden = true;
          $hub.tabs.inventory_by_lot_snapshot.hidden = true;
      }
      else if (inventoryType == 3) {
          $hub.tabs.inventory_by_lot_snapshot.hidden = false;
          $hub.tabs.inventory_by_lot_snapshot.activate()
  
          $hub.tabs.inventory_by_location.hidden = true;
          $hub.tabs.inventory_by_lot.hidden = true;
          $hub.tabs.inventory_by_lp.hidden = true;
          $hub.tabs.inventory_by_material.hidden = true;
          $hub.tabs.inventory_by_material_snapshot.hidden = true;
          $hub.tabs.inventory_by_licenseplate_snapshot.hidden = true;
      }
  
      else if (inventoryType == 4) {
  
  
          $hub.tabs.inventory_by_material_snapshot.hidden = false;
          $hub.tabs.inventory_by_material_snapshot.activate()
  
          $hub.tabs.inventory_by_location.hidden = true;
          $hub.tabs.inventory_by_lot.hidden = true;
          $hub.tabs.inventory_by_lp.hidden = true;
          $hub.tabs.inventory_by_material.hidden = true;
          $hub.tabs.inventory_by_licenseplate_snapshot.hidden = true;
          $hub.tabs.inventory_by_lot_snapshot.hidden = true;
      }
  } else {
      if (inventoryType == 2) {
          $hub.tabs.inventory_by_lp.hidden = false;
  
          $hub.tabs.inventory_by_lp.activate()
  
          $hub.tabs.inventory_by_location.hidden = true;
          $hub.tabs.inventory_by_lot.hidden = true;
          $hub.tabs.inventory_by_material.hidden = true;
  
          $hub.tabs.inventory_by_licenseplate_snapshot.hidden = true;
          $hub.tabs.inventory_by_lot_snapshot.hidden = true;
          $hub.tabs.inventory_by_material_snapshot.hidden = true;
  
      }
      else if (inventoryType == 3) {
          $hub.tabs.inventory_by_lot.hidden = false;
  
          $hub.tabs.inventory_by_lot.activate()
  
          $hub.tabs.inventory_by_location.hidden = true;
          $hub.tabs.inventory_by_lp.hidden = true;
          $hub.tabs.inventory_by_material.hidden = true;
  
          $hub.tabs.inventory_by_licenseplate_snapshot.hidden = true;
          $hub.tabs.inventory_by_lot_snapshot.hidden = true;
          $hub.tabs.inventory_by_material_snapshot.hidden = true;
  
      }
  
      else if (inventoryType == 4) {
  
          $hub.tabs.inventory_by_material.hidden = false;
  
          $hub.tabs.inventory_by_material.activate()
  
          $hub.tabs.inventory_by_location.hidden = true;
          $hub.tabs.inventory_by_lp.hidden = true;
          $hub.tabs.inventory_by_lot.hidden = true;
  
          $hub.tabs.inventory_by_licenseplate_snapshot.hidden = true;
          $hub.tabs.inventory_by_lot_snapshot.hidden = true;
          $hub.tabs.inventory_by_material_snapshot.hidden = true;
  
      }
  
  }
  }
  on_inventory_snapshot_rules_clicked(event = null) {
    return this.on_inventory_snapshot_rules_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_inventory_snapshot_rules_clickedInternal(
    $hub: app_custom_inventory_hubComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.logger.log('app', 'custom_inventory_hub.on_inventory_snapshot_rules_clicked');
  
  
  await $shell.Inventory.openinventory_snapshot_rules_gridDialog();
  }
  //#endregion private flows
}
