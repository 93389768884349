import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';
import { Addresses_ReportService } from './Addresses.report.index';
import { Carriers_ReportService } from './Carriers.report.index';
import { PrintNode_ReportService } from './PrintNode.report.index';
import { PalletTransactions_ReportService } from './PalletTransactions.report.index';
import { Lots_ReportService } from './Lots.report.index';
import { Owners_ReportService } from './Owners.report.index';
import { Discussions_ReportService } from './Discussions.report.index';
import { ExcelOrderImport_ReportService } from './ExcelOrderImport.report.index';
import { Document360_ReportService } from './Document360.report.index';
import { Usersnap_ReportService } from './Usersnap.report.index';
import { Locations_ReportService } from './Locations.report.index';
import { Ultraship_ReportService } from './Ultraship.report.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { PurchaseOrders_ReportService } from './PurchaseOrders.report.index';
import { Inventory_ReportService } from './Inventory.report.index';
import { ExcelInventoryImport_ReportService } from './ExcelInventoryImport.report.index';
import { Dynamics365BusinessCentral_ReportService } from './Dynamics365BusinessCentral.report.index';
import { DockAppointments_ReportService } from './DockAppointments.report.index';
import { Materials_ReportService } from './Materials.report.index';
import { Waves_ReportService } from './Waves.report.index';
import { SalesOrders_ReportService } from './SalesOrders.report.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { FootPrintApiManager_ReportService } from './FootPrintApiManager.report.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { Reports_ReportService } from './Reports.report.index';


@Injectable({ providedIn: 'root' })
export class app_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.Addresses = this.injector.get(Addresses_ReportService);
    this.Carriers = this.injector.get(Carriers_ReportService);
    this.PrintNode = this.injector.get(PrintNode_ReportService);
    this.PalletTransactions = this.injector.get(PalletTransactions_ReportService);
    this.Lots = this.injector.get(Lots_ReportService);
    this.Owners = this.injector.get(Owners_ReportService);
    this.Discussions = this.injector.get(Discussions_ReportService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_ReportService);
    this.Document360 = this.injector.get(Document360_ReportService);
    this.Usersnap = this.injector.get(Usersnap_ReportService);
    this.Locations = this.injector.get(Locations_ReportService);
    this.Ultraship = this.injector.get(Ultraship_ReportService);
    this.Notifications = this.injector.get(Notifications_ReportService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_ReportService);
    this.Inventory = this.injector.get(Inventory_ReportService);
    this.ExcelInventoryImport = this.injector.get(ExcelInventoryImport_ReportService);
    this.Dynamics365BusinessCentral = this.injector.get(Dynamics365BusinessCentral_ReportService);
    this.DockAppointments = this.injector.get(DockAppointments_ReportService);
    this.Materials = this.injector.get(Materials_ReportService);
    this.Waves = this.injector.get(Waves_ReportService);
    this.SalesOrders = this.injector.get(SalesOrders_ReportService);
    this.Invoices = this.injector.get(Invoices_ReportService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_ReportService);
    this.FootPrintManager = this.injector.get(FootPrintManager_ReportService);
    this.Reports = this.injector.get(Reports_ReportService);
  }
    public Utilities: Utilities_ReportService;
    public Addresses: Addresses_ReportService;
    public Carriers: Carriers_ReportService;
    public PrintNode: PrintNode_ReportService;
    public PalletTransactions: PalletTransactions_ReportService;
    public Lots: Lots_ReportService;
    public Owners: Owners_ReportService;
    public Discussions: Discussions_ReportService;
    public ExcelOrderImport: ExcelOrderImport_ReportService;
    public Document360: Document360_ReportService;
    public Usersnap: Usersnap_ReportService;
    public Locations: Locations_ReportService;
    public Ultraship: Ultraship_ReportService;
    public Notifications: Notifications_ReportService;
    public PurchaseOrders: PurchaseOrders_ReportService;
    public Inventory: Inventory_ReportService;
    public ExcelInventoryImport: ExcelInventoryImport_ReportService;
    public Dynamics365BusinessCentral: Dynamics365BusinessCentral_ReportService;
    public DockAppointments: DockAppointments_ReportService;
    public Materials: Materials_ReportService;
    public Waves: Waves_ReportService;
    public SalesOrders: SalesOrders_ReportService;
    public Invoices: Invoices_ReportService;
    public FootPrintApiManager: FootPrintApiManager_ReportService;
    public FootPrintManager: FootPrintManager_ReportService;
    public Reports: Reports_ReportService;

  public app: app_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
}

