import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { SalesOrders_salesorders_library_homeComponent } from './SalesOrders.salesorders_library_home.component';
import { SalesOrders_load_slip_reportComponent } from './SalesOrders.load_slip_report.component';
import { SalesOrders_outbound_bill_of_lading_reportComponent } from './SalesOrders.outbound_bill_of_lading_report.component';
import { SalesOrders_packing_list_reportComponent } from './SalesOrders.packing_list_report.component';
import { SalesOrders_pick_slip_by_orderId_reportComponent } from './SalesOrders.pick_slip_by_orderId_report.component';
import { SalesOrders_allocatable_locations_dd_singleComponent } from './SalesOrders.allocatable_locations_dd_single.component';
import { SalesOrders_container_sizes_dd_singleComponent } from './SalesOrders.container_sizes_dd_single.component';
import { SalesOrders_countries_dd_singleComponent } from './SalesOrders.countries_dd_single.component';
import { SalesOrders_freight_terms_dd_singleComponent } from './SalesOrders.freight_terms_dd_single.component';
import { SalesOrders_licenseplates_by_locationId_lotId_packagingId_dd_singleComponent } from './SalesOrders.licenseplates_by_locationId_lotId_packagingId_dd_single.component';
import { SalesOrders_orderclasses_dd_singleComponent } from './SalesOrders.orderclasses_dd_single.component';
import { SalesOrders_orderstatuses_dd_singleComponent } from './SalesOrders.orderstatuses_dd_single.component';
import { SalesOrders_outbound_processing_strategies_dd_singleComponent } from './SalesOrders.outbound_processing_strategies_dd_single.component';
import { SalesOrders_outbound_processing_strategies_hardcoded_dd_singleComponent } from './SalesOrders.outbound_processing_strategies_hardcoded_dd_single.component';
import { SalesOrders_sales_order_date_types_dd_singleComponent } from './SalesOrders.sales_order_date_types_dd_single.component';
import { SalesOrders_shipment_priorities_dd_singleComponent } from './SalesOrders.shipment_priorities_dd_single.component';
import { SalesOrders_wave_priorities_dd_singleComponent } from './SalesOrders.wave_priorities_dd_single.component';
import { SalesOrders_allocatable_locations_dd_multiComponent } from './SalesOrders.allocatable_locations_dd_multi.component';
import { SalesOrders_container_sizes_dd_multiComponent } from './SalesOrders.container_sizes_dd_multi.component';
import { SalesOrders_countries_dd_multiComponent } from './SalesOrders.countries_dd_multi.component';
import { SalesOrders_freight_terms_dd_multiComponent } from './SalesOrders.freight_terms_dd_multi.component';
import { SalesOrders_licenseplates_by_locationId_lotId_packagingId_dd_multiComponent } from './SalesOrders.licenseplates_by_locationId_lotId_packagingId_dd_multi.component';
import { SalesOrders_orderclasses_dd_multiComponent } from './SalesOrders.orderclasses_dd_multi.component';
import { SalesOrders_orderstatuses_dd_multiComponent } from './SalesOrders.orderstatuses_dd_multi.component';
import { SalesOrders_outbound_processing_strategies_dd_multiComponent } from './SalesOrders.outbound_processing_strategies_dd_multi.component';
import { SalesOrders_outbound_processing_strategies_hardcoded_dd_multiComponent } from './SalesOrders.outbound_processing_strategies_hardcoded_dd_multi.component';
import { SalesOrders_sales_order_date_types_dd_multiComponent } from './SalesOrders.sales_order_date_types_dd_multi.component';
import { SalesOrders_shipment_priorities_dd_multiComponent } from './SalesOrders.shipment_priorities_dd_multi.component';
import { SalesOrders_wave_priorities_dd_multiComponent } from './SalesOrders.wave_priorities_dd_multi.component';
import { SalesOrders_order_loaded_percentage_widgetComponent } from './SalesOrders.order_loaded_percentage_widget.component';
import { SalesOrders_order_picked_percentage_widgetComponent } from './SalesOrders.order_picked_percentage_widget.component';
import { SalesOrders_order_total_completed_order_count_by_date_widgetComponent } from './SalesOrders.order_total_completed_order_count_by_date_widget.component';
import { SalesOrders_order_total_pallet_count_widgetComponent } from './SalesOrders.order_total_pallet_count_widget.component';
import { SalesOrders_order_total_picked_gross_by_date_widgetComponent } from './SalesOrders.order_total_picked_gross_by_date_widget.component';
import { SalesOrders_order_total_picked_gross_widgetComponent } from './SalesOrders.order_total_picked_gross_widget.component';
import { SalesOrders_order_total_picked_order_count_by_date_widgetComponent } from './SalesOrders.order_total_picked_order_count_by_date_widget.component';
import { SalesOrders_order_total_picked_units_by_date_widgetComponent } from './SalesOrders.order_total_picked_units_by_date_widget.component';
import { SalesOrders_order_total_picked_units_widgetComponent } from './SalesOrders.order_total_picked_units_widget.component';
import { SalesOrders_order_total_units_widgetComponent } from './SalesOrders.order_total_units_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class SalesOrders_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public SalesOrders: SalesOrders_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public opensalesorders_library_home(replaceCurrentView?: boolean) {
    this.logger.log('SalesOrders', 'salesorders_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'SalesOrders_salesorders_library_home',
        component: SalesOrders_salesorders_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opensalesorders_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('SalesOrders', 'salesorders_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      SalesOrders_salesorders_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openload_slip_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Load Slip',
        referenceName: 'SalesOrders_load_slip_report',
        component: SalesOrders_load_slip_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openload_slip_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      SalesOrders_load_slip_reportComponent,
      'Load Slip',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openoutbound_bill_of_lading_report(inParams:{ orderId: number, shipmentId?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Custom Outbound Bill of Lading ',
        referenceName: 'SalesOrders_outbound_bill_of_lading_report',
        component: SalesOrders_outbound_bill_of_lading_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openoutbound_bill_of_lading_reportDialog(
    inParams:{ orderId: number, shipmentId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      SalesOrders_outbound_bill_of_lading_reportComponent,
      'Custom Outbound Bill of Lading ',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpacking_list_report(inParams:{ orderId?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Generic Packing List Report',
        referenceName: 'SalesOrders_packing_list_report',
        component: SalesOrders_packing_list_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpacking_list_reportDialog(
    inParams:{ orderId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      SalesOrders_packing_list_reportComponent,
      'Generic Packing List Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpick_slip_by_orderId_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Pick Slip',
        referenceName: 'SalesOrders_pick_slip_by_orderId_report',
        component: SalesOrders_pick_slip_by_orderId_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpick_slip_by_orderId_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      SalesOrders_pick_slip_by_orderId_reportComponent,
      'Pick Slip',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'SalesOrders_salesorders_library_home') {
      this.logger.log('SalesOrders', 'salesorders_library_home');
      const title = 'Home';
      const component = SalesOrders_salesorders_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_load_slip_report') {
      const title = 'Load Slip';
      const component = SalesOrders_load_slip_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_outbound_bill_of_lading_report') {
      const title = 'Custom Outbound Bill of Lading ';
      const component = SalesOrders_outbound_bill_of_lading_reportComponent;
      const inParams:{ orderId: number, shipmentId?: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_packing_list_report') {
      const title = 'Generic Packing List Report';
      const component = SalesOrders_packing_list_reportComponent;
      const inParams:{ orderId?: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_pick_slip_by_orderId_report') {
      const title = 'Pick Slip';
      const component = SalesOrders_pick_slip_by_orderId_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_allocatable_locations_dd_single') {
      this.logger.log('SalesOrders', 'allocatable_locations_dd_single');
      const title = 'allocatable_locations_dd';
      const component = SalesOrders_allocatable_locations_dd_singleComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_container_sizes_dd_single') {
      this.logger.log('SalesOrders', 'container_sizes_dd_single');
      const title = 'Container Sizes';
      const component = SalesOrders_container_sizes_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_countries_dd_single') {
      this.logger.log('SalesOrders', 'countries_dd_single');
      const title = 'List of countries.';
      const component = SalesOrders_countries_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_freight_terms_dd_single') {
      this.logger.log('SalesOrders', 'freight_terms_dd_single');
      const title = 'Freight Terms';
      const component = SalesOrders_freight_terms_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_licenseplates_by_locationId_lotId_packagingId_dd_single') {
      const title = 'Inventory licenseplates dropdown';
      const component = SalesOrders_licenseplates_by_locationId_lotId_packagingId_dd_singleComponent;
      const inParams:{ locationId: number, lotId: number, packagedId: number } = { locationId: null, lotId: null, packagedId: null };
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedId'))) {
        const paramValueString = params.get('packagedId');
        inParams.packagedId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_orderclasses_dd_single') {
      const title = 'Orderclasses by purchase order Dropdown.';
      const component = SalesOrders_orderclasses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_orderstatuses_dd_single') {
      const title = 'Order Statuses';
      const component = SalesOrders_orderstatuses_dd_singleComponent;
      const inParams:{ statusIds?: number[] } = { statusIds: [] };
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_outbound_processing_strategies_dd_single') {
      const title = 'outbound_processing_strategies_dd';
      const component = SalesOrders_outbound_processing_strategies_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_outbound_processing_strategies_hardcoded_dd_single') {
      const title = 'outbound_processing_strategies_hardcoded_dd';
      const component = SalesOrders_outbound_processing_strategies_hardcoded_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_sales_order_date_types_dd_single') {
      const title = 'sales_order_date_types_dd';
      const component = SalesOrders_sales_order_date_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_shipment_priorities_dd_single') {
      this.logger.log('SalesOrders', 'shipment_priorities_dd_single');
      const title = 'Shipment priorities';
      const component = SalesOrders_shipment_priorities_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_wave_priorities_dd_single') {
      const title = 'Wave Priotities';
      const component = SalesOrders_wave_priorities_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_allocatable_locations_dd_multi') {
      this.logger.log('SalesOrders', 'allocatable_locations_dd_multi');
      const title = 'allocatable_locations_dd';
      const component = SalesOrders_allocatable_locations_dd_multiComponent;
      const inParams:{ warehouseId: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_container_sizes_dd_multi') {
      this.logger.log('SalesOrders', 'container_sizes_dd_multi');
      const title = 'Container Sizes';
      const component = SalesOrders_container_sizes_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_countries_dd_multi') {
      this.logger.log('SalesOrders', 'countries_dd_multi');
      const title = 'List of countries.';
      const component = SalesOrders_countries_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_freight_terms_dd_multi') {
      this.logger.log('SalesOrders', 'freight_terms_dd_multi');
      const title = 'Freight Terms';
      const component = SalesOrders_freight_terms_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_licenseplates_by_locationId_lotId_packagingId_dd_multi') {
      const title = 'Inventory licenseplates dropdown';
      const component = SalesOrders_licenseplates_by_locationId_lotId_packagingId_dd_multiComponent;
      const inParams:{ locationId: number, lotId: number, packagedId: number } = { locationId: null, lotId: null, packagedId: null };
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedId'))) {
        const paramValueString = params.get('packagedId');
        inParams.packagedId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_orderclasses_dd_multi') {
      const title = 'Orderclasses by purchase order Dropdown.';
      const component = SalesOrders_orderclasses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_orderstatuses_dd_multi') {
      const title = 'Order Statuses';
      const component = SalesOrders_orderstatuses_dd_multiComponent;
      const inParams:{ statusIds?: number[] } = { statusIds: [] };
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_outbound_processing_strategies_dd_multi') {
      const title = 'outbound_processing_strategies_dd';
      const component = SalesOrders_outbound_processing_strategies_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_outbound_processing_strategies_hardcoded_dd_multi') {
      const title = 'outbound_processing_strategies_hardcoded_dd';
      const component = SalesOrders_outbound_processing_strategies_hardcoded_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_sales_order_date_types_dd_multi') {
      const title = 'sales_order_date_types_dd';
      const component = SalesOrders_sales_order_date_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_shipment_priorities_dd_multi') {
      this.logger.log('SalesOrders', 'shipment_priorities_dd_multi');
      const title = 'Shipment priorities';
      const component = SalesOrders_shipment_priorities_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_wave_priorities_dd_multi') {
      const title = 'Wave Priotities';
      const component = SalesOrders_wave_priorities_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_order_loaded_percentage_widget') {
      const title = 'Loaded';
      const component = SalesOrders_order_loaded_percentage_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_order_picked_percentage_widget') {
      const title = 'Picked';
      const component = SalesOrders_order_picked_percentage_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_order_total_completed_order_count_by_date_widget') {
      const title = 'Completed today';
      const component = SalesOrders_order_total_completed_order_count_by_date_widgetComponent;
      const inParams:{ date: string, ownerId?: number, projectId?: number, warehouseId?: number[] } = { date: null, ownerId: null, projectId: null, warehouseId: [] };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_order_total_pallet_count_widget') {
      const title = 'Total pallets';
      const component = SalesOrders_order_total_pallet_count_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_order_total_picked_gross_by_date_widget') {
      this.logger.log('SalesOrders', 'order_total_picked_gross_by_date_widget');
      const title = 'Picked gross today';
      const component = SalesOrders_order_total_picked_gross_by_date_widgetComponent;
      const inParams:{ date: string, ownerId?: number, projectId?: number, warehouseId?: number[], orderStatusIds?: number[] } = { date: null, ownerId: null, projectId: null, warehouseId: [], orderStatusIds: [] };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('orderStatusIds'))) {
        const paramValueString = params.get('orderStatusIds');
        inParams.orderStatusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_order_total_picked_gross_widget') {
      const title = 'Total gross picked';
      const component = SalesOrders_order_total_picked_gross_widgetComponent;
      const inParams:{ orderId: number, shipmentId: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_order_total_picked_order_count_by_date_widget') {
      const title = 'Picked orders today';
      const component = SalesOrders_order_total_picked_order_count_by_date_widgetComponent;
      const inParams:{ date: string, ownerId?: number, projectId?: number, warehouseId?: number[], orderStatusIds?: number[] } = { date: null, ownerId: null, projectId: null, warehouseId: [], orderStatusIds: [] };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('orderStatusIds'))) {
        const paramValueString = params.get('orderStatusIds');
        inParams.orderStatusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_order_total_picked_units_by_date_widget') {
      const title = 'Picked units today';
      const component = SalesOrders_order_total_picked_units_by_date_widgetComponent;
      const inParams:{ date: string, ownerId?: number, projectId?: number, warehouseId?: number[], orderStatusIds?: number[] } = { date: null, ownerId: null, projectId: null, warehouseId: [], orderStatusIds: [] };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('orderStatusIds'))) {
        const paramValueString = params.get('orderStatusIds');
        inParams.orderStatusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_order_total_picked_units_widget') {
      const title = 'Total picked';
      const component = SalesOrders_order_total_picked_units_widgetComponent;
      const inParams:{ orderId: number, shipmentId: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_order_total_units_widget') {
      const title = 'Total amount';
      const component = SalesOrders_order_total_units_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
