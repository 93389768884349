import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Ultraship_ds_get_address_by_accountId_addressIdService } from './Ultraship.datasource.index';
import { Ultraship_ds_get_address_by_warehouseIdService } from './Ultraship.datasource.index';
import { Ultraship_ds_get_all_projectsService } from './Ultraship.datasource.index';
import { Ultraship_ds_get_load_container_by_lookupCodeService } from './Ultraship.datasource.index';
import { Ultraship_ds_get_load_container_details_by_loadContainerIdService } from './Ultraship.datasource.index';
import { Ultraship_ds_get_load_containers_by_project_and_filtersService } from './Ultraship.datasource.index';
import { Ultraship_ds_get_load_containers_to_sendService } from './Ultraship.datasource.index';
import { Ultraship_ds_get_order_by_lookupCode_referencesService } from './Ultraship.datasource.index';
import { Ultraship_ds_get_order_details_by_orderId_shipmentIdService } from './Ultraship.datasource.index';
import { Ultraship_ds_get_orderaddresses_by_orderId_typeIdService } from './Ultraship.datasource.index';
import { Ultraship_ds_get_orders_by_project_and_filtersService } from './Ultraship.datasource.index';
import { Ultraship_ds_get_orders_to_sendService } from './Ultraship.datasource.index';
import { Ultraship_ds_get_project_by_projectIdService } from './Ultraship.datasource.index';
import { Ultraship_ds_get_shipment_for_updates_by_shipmentIdService } from './Ultraship.datasource.index';
import { Ultraship_ds_owners_ddService } from './Ultraship.datasource.index';
import { Ultraship_ds_projects_ddService } from './Ultraship.datasource.index';

@Injectable({ providedIn: 'root' })
export class Ultraship_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Ultraship: Ultraship_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_address_by_accountId_addressId: Ultraship_ds_get_address_by_accountId_addressIdService;
  public get ds_get_address_by_accountId_addressId(): Ultraship_ds_get_address_by_accountId_addressIdService {
    if(!this._ds_get_address_by_accountId_addressId) {
      this._ds_get_address_by_accountId_addressId = this.injector.get(Ultraship_ds_get_address_by_accountId_addressIdService);
    }
    return this._ds_get_address_by_accountId_addressId;
  }
  private _ds_get_address_by_warehouseId: Ultraship_ds_get_address_by_warehouseIdService;
  public get ds_get_address_by_warehouseId(): Ultraship_ds_get_address_by_warehouseIdService {
    if(!this._ds_get_address_by_warehouseId) {
      this._ds_get_address_by_warehouseId = this.injector.get(Ultraship_ds_get_address_by_warehouseIdService);
    }
    return this._ds_get_address_by_warehouseId;
  }
  private _ds_get_all_projects: Ultraship_ds_get_all_projectsService;
  public get ds_get_all_projects(): Ultraship_ds_get_all_projectsService {
    if(!this._ds_get_all_projects) {
      this._ds_get_all_projects = this.injector.get(Ultraship_ds_get_all_projectsService);
    }
    return this._ds_get_all_projects;
  }
  private _ds_get_load_container_by_lookupCode: Ultraship_ds_get_load_container_by_lookupCodeService;
  public get ds_get_load_container_by_lookupCode(): Ultraship_ds_get_load_container_by_lookupCodeService {
    if(!this._ds_get_load_container_by_lookupCode) {
      this._ds_get_load_container_by_lookupCode = this.injector.get(Ultraship_ds_get_load_container_by_lookupCodeService);
    }
    return this._ds_get_load_container_by_lookupCode;
  }
  private _ds_get_load_container_details_by_loadContainerId: Ultraship_ds_get_load_container_details_by_loadContainerIdService;
  public get ds_get_load_container_details_by_loadContainerId(): Ultraship_ds_get_load_container_details_by_loadContainerIdService {
    if(!this._ds_get_load_container_details_by_loadContainerId) {
      this._ds_get_load_container_details_by_loadContainerId = this.injector.get(Ultraship_ds_get_load_container_details_by_loadContainerIdService);
    }
    return this._ds_get_load_container_details_by_loadContainerId;
  }
  private _ds_get_load_containers_by_project_and_filters: Ultraship_ds_get_load_containers_by_project_and_filtersService;
  public get ds_get_load_containers_by_project_and_filters(): Ultraship_ds_get_load_containers_by_project_and_filtersService {
    if(!this._ds_get_load_containers_by_project_and_filters) {
      this._ds_get_load_containers_by_project_and_filters = this.injector.get(Ultraship_ds_get_load_containers_by_project_and_filtersService);
    }
    return this._ds_get_load_containers_by_project_and_filters;
  }
  private _ds_get_load_containers_to_send: Ultraship_ds_get_load_containers_to_sendService;
  public get ds_get_load_containers_to_send(): Ultraship_ds_get_load_containers_to_sendService {
    if(!this._ds_get_load_containers_to_send) {
      this._ds_get_load_containers_to_send = this.injector.get(Ultraship_ds_get_load_containers_to_sendService);
    }
    return this._ds_get_load_containers_to_send;
  }
  private _ds_get_order_by_lookupCode_references: Ultraship_ds_get_order_by_lookupCode_referencesService;
  public get ds_get_order_by_lookupCode_references(): Ultraship_ds_get_order_by_lookupCode_referencesService {
    if(!this._ds_get_order_by_lookupCode_references) {
      this._ds_get_order_by_lookupCode_references = this.injector.get(Ultraship_ds_get_order_by_lookupCode_referencesService);
    }
    return this._ds_get_order_by_lookupCode_references;
  }
  private _ds_get_order_details_by_orderId_shipmentId: Ultraship_ds_get_order_details_by_orderId_shipmentIdService;
  public get ds_get_order_details_by_orderId_shipmentId(): Ultraship_ds_get_order_details_by_orderId_shipmentIdService {
    if(!this._ds_get_order_details_by_orderId_shipmentId) {
      this._ds_get_order_details_by_orderId_shipmentId = this.injector.get(Ultraship_ds_get_order_details_by_orderId_shipmentIdService);
    }
    return this._ds_get_order_details_by_orderId_shipmentId;
  }
  private _ds_get_orderaddresses_by_orderId_typeId: Ultraship_ds_get_orderaddresses_by_orderId_typeIdService;
  public get ds_get_orderaddresses_by_orderId_typeId(): Ultraship_ds_get_orderaddresses_by_orderId_typeIdService {
    if(!this._ds_get_orderaddresses_by_orderId_typeId) {
      this._ds_get_orderaddresses_by_orderId_typeId = this.injector.get(Ultraship_ds_get_orderaddresses_by_orderId_typeIdService);
    }
    return this._ds_get_orderaddresses_by_orderId_typeId;
  }
  private _ds_get_orders_by_project_and_filters: Ultraship_ds_get_orders_by_project_and_filtersService;
  public get ds_get_orders_by_project_and_filters(): Ultraship_ds_get_orders_by_project_and_filtersService {
    if(!this._ds_get_orders_by_project_and_filters) {
      this._ds_get_orders_by_project_and_filters = this.injector.get(Ultraship_ds_get_orders_by_project_and_filtersService);
    }
    return this._ds_get_orders_by_project_and_filters;
  }
  private _ds_get_orders_to_send: Ultraship_ds_get_orders_to_sendService;
  public get ds_get_orders_to_send(): Ultraship_ds_get_orders_to_sendService {
    if(!this._ds_get_orders_to_send) {
      this._ds_get_orders_to_send = this.injector.get(Ultraship_ds_get_orders_to_sendService);
    }
    return this._ds_get_orders_to_send;
  }
  private _ds_get_project_by_projectId: Ultraship_ds_get_project_by_projectIdService;
  public get ds_get_project_by_projectId(): Ultraship_ds_get_project_by_projectIdService {
    if(!this._ds_get_project_by_projectId) {
      this._ds_get_project_by_projectId = this.injector.get(Ultraship_ds_get_project_by_projectIdService);
    }
    return this._ds_get_project_by_projectId;
  }
  private _ds_get_shipment_for_updates_by_shipmentId: Ultraship_ds_get_shipment_for_updates_by_shipmentIdService;
  public get ds_get_shipment_for_updates_by_shipmentId(): Ultraship_ds_get_shipment_for_updates_by_shipmentIdService {
    if(!this._ds_get_shipment_for_updates_by_shipmentId) {
      this._ds_get_shipment_for_updates_by_shipmentId = this.injector.get(Ultraship_ds_get_shipment_for_updates_by_shipmentIdService);
    }
    return this._ds_get_shipment_for_updates_by_shipmentId;
  }
  private _ds_owners_dd: Ultraship_ds_owners_ddService;
  public get ds_owners_dd(): Ultraship_ds_owners_ddService {
    if(!this._ds_owners_dd) {
      this._ds_owners_dd = this.injector.get(Ultraship_ds_owners_ddService);
    }
    return this._ds_owners_dd;
  }
  private _ds_projects_dd: Ultraship_ds_projects_ddService;
  public get ds_projects_dd(): Ultraship_ds_projects_ddService {
    if(!this._ds_projects_dd) {
      this._ds_projects_dd = this.injector.get(Ultraship_ds_projects_ddService);
    }
    return this._ds_projects_dd;
  }
}

