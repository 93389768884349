import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface Iapp_custom_inventory_lot_gridComponentEntity {
WarehouseId?: number, LotId?: number, PackagedId?: number, GrossWeight?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, MaterialId?: number, ReceiveDate?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } } }, Packaging?: { ShortName?: string }, Warehouse?: { Name?: string }, WeightUom?: { Short_name?: string }}

interface Iapp_custom_inventory_lot_gridComponentInParams {
  ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[], vendorReference?: string, ownerReference?: string}


class app_custom_inventory_lot_gridComponentRowModel extends GridRowModel {
  grid: app_custom_inventory_lot_gridComponent;
  entity: Iapp_custom_inventory_lot_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    project: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    material: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    lot: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    total_packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    available_packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    packaging: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    gross_weight: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    weight_uom: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    inbound_order: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    vendor_lot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    expiration_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    manufacture_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    received_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    material_description: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: app_custom_inventory_lot_gridComponent, entity: Iapp_custom_inventory_lot_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.WarehouseId,this.entity.LotId,this.entity.PackagedId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: app_custom_inventory_lot_gridComponent, entity?: Iapp_custom_inventory_lot_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.WarehouseId,this.entity.LotId,this.entity.PackagedId].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = {};
    set($resultKey, 'WarehouseId',this.entity.WarehouseId);
    set($resultKey, 'LotId',this.entity.LotId);
    set($resultKey, 'PackagedId',this.entity.PackagedId);
    const inParams = {
      $keys:[$resultKey],
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      materialIds:  $grid.inParams.materialIds ,
      skip:  null ,
      take:  null ,
      vendorReference:  $grid.inParams.vendorReference ,
      ownerReference:  $grid.inParams.ownerReference ,
    };
    const data = await this.datasources.app.custom_ds_inventory_lot_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity.Warehouse?.Name;
    (this.cells.project.displayControl as TextModel).text = $row.entity.Lot?.Material?.Project?.LookupCode;
    (this.cells.material.displayControl as TextModel).text = $row.entity.Lot?.Material?.LookupCode;
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.total_packaged_amount.displayControl as TextModel).text = $row.entity.PackagedAmount?.toString();
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.Packaging?.ShortName;
    (this.cells.gross_weight.displayControl as TextModel).text = $row.entity.GrossWeight?.toString();
    (this.cells.weight_uom.displayControl as TextModel).text = $row.entity.WeightUom?.Short_name;
    (this.cells.material_description.displayControl as TextModel).text = $row.entity.Lot?.Material?.Description;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'project' : {
        this.on_project_clicked();
        break;
      }
      case 'material' : {
        this.on_material_clicked();
        break;
      }
      case 'lot' : {
        this.on_lot_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_lot_clicked(event = null) {
    return this.on_lot_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_clickedInternal(
    $row: app_custom_inventory_lot_gridComponentRowModel,
  $grid: app_custom_inventory_lot_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootPrintManager.opensingle_lot_hub({lotId: $row.entity.LotId});
  }
  on_material_clicked(event = null) {
    return this.on_material_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_clickedInternal(
    $row: app_custom_inventory_lot_gridComponentRowModel,
  $grid: app_custom_inventory_lot_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootPrintManager.opensingle_material_hub({materialId: $row.entity.Lot.MaterialId});
  }
  on_project_clicked(event = null) {
    return this.on_project_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_clickedInternal(
    $row: app_custom_inventory_lot_gridComponentRowModel,
  $grid: app_custom_inventory_lot_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootPrintManager.opensingle_project_hub({projectId: $row.entity.Lot.Material.ProjectId})
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: app_custom_inventory_lot_gridComponentRowModel,
  $grid: app_custom_inventory_lot_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  // Apply date format
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.ExpirationDate) && $utils.isDefined($settings.FootPrintManager.DateFormat)) {
      var dateFormat = $settings.FootPrintManager.DateFormat;
      var expirationDate = $utils.date.format($row.entity.Lot?.VendorLot?.ExpirationDate, dateFormat);
      $row.cells.expiration_date.displayControl.text = expirationDate;
  }
  
  
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.ManufactureDate) && $utils.isDefined($settings.FootPrintManager.DateFormat)) {
      var dateFormat = $settings.FootPrintManager.DateFormat;
      var manufactureDate = $utils.date.format($row.entity.Lot?.VendorLot?.ManufactureDate, dateFormat);
      $row.cells.manufacture_date.displayControl.text = manufactureDate;
  }
  
  
  if ($utils.isDefined($row.entity.Lot?.ReceiveDate) && $utils.isDefined($settings.FootPrintManager.DateFormat)) {
      var dateFormat = $settings.FootPrintManager.DateFormat;
      var receivedDate = $utils.date.format($row.entity.Lot?.ReceiveDate, dateFormat);
      $row.cells.received_date.displayControl.text = receivedDate;
  }
  
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.Vat)) {
  
      $row.cells.vendor_lot.displayControl.text = $row.entity.Lot?.VendorLot?.LookupCode + ' ' + $row.entity.Lot?.VendorLot?.Vat
  } else {
      $row.cells.vendor_lot.displayControl.text = $row.entity.Lot?.VendorLot?.LookupCode
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-custom_inventory_lot_grid',
  templateUrl: './app.custom_inventory_lot_grid.component.html'
})
export class app_custom_inventory_lot_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: Iapp_custom_inventory_lot_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: Iapp_custom_inventory_lot_gridComponentInParams = { ownerId: null, projectId: null, warehouseId: null, materialIds: [], vendorReference: null, ownerReference: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse', false, false, null),       project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       material: new GridHeaderModel(new HeaderStyles(null, null), 'Material', false, false, null),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'Lot', false, false, null),       total_packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Total amount', false, false, null),       available_packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Available amount', false, false, null),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'Packaging', false, false, null),       gross_weight: new GridHeaderModel(new HeaderStyles(null, null), 'Gross weight', false, false, null),       weight_uom: new GridHeaderModel(new HeaderStyles(null, null), 'Weight UOM', false, false, null),       inbound_order: new GridHeaderModel(new HeaderStyles(null, null), 'Inbound order', false, false, null),       vendor_lot: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor lot', false, false, null),       expiration_date: new GridHeaderModel(new HeaderStyles(null, null), 'Expiration date', false, false, null),       manufacture_date: new GridHeaderModel(new HeaderStyles(null, null), 'Manufacture date', false, false, null),       received_date: new GridHeaderModel(new HeaderStyles(null, null), 'Received date', false, false, null),       material_description: new GridHeaderModel(new HeaderStyles(null, null), 'Material description', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: app_custom_inventory_lot_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('materialIds') set $inParams_materialIds(value: any) {
    this.inParams['materialIds'] = value;
  }
  get $inParams_materialIds(): any {
    return this.inParams['materialIds'] ;
  }
  @Input('vendorReference') set $inParams_vendorReference(value: any) {
    this.inParams['vendorReference'] = value;
  }
  get $inParams_vendorReference(): any {
    return this.inParams['vendorReference'] ;
  }
  @Input('ownerReference') set $inParams_ownerReference(value: any) {
    this.inParams['ownerReference'] = value;
  }
  get $inParams_ownerReference(): any {
    return this.inParams['ownerReference'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Inventory by lot';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 50;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      materialIds:  $grid.inParams.materialIds ,
      skip:  null ,
      take:  null ,
      vendorReference:  $grid.inParams.vendorReference ,
      ownerReference:  $grid.inParams.ownerReference ,
    };
    try {
    const data = await this.datasources.app.custom_ds_inventory_lot_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new app_custom_inventory_lot_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: app_custom_inventory_lot_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: app_custom_inventory_lot_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  
  const exportLimit = $settings.FootPrintManager.ExcelExportLimit
  var topLimit: number = 5000;
  
  // Initialize
  var wb = $event;
  var transactions = [];
  var allData: any[] = [];
  var lot_availability: any[] = [];
  var inboundOrders: any[] = [];
  var freshData: any[] = [];
  
  // Set Date/Time Formats
  const dateFormat = `${$settings.FootPrintManager.DateFormat}`;
  
  
  try {
  
      var totalRecords = (await $datasources.Inventory.ds_inventory_lot_grid.get({
          ownerId: $grid.inParams.ownerId,
          projectId: $grid.inParams.projectId,
          warehouseId: $grid.inParams.warehouseId,
          materialIds: $grid.inParams.materialIds,
          fullTextSearch: $grid.fullTextSearch,
          take: 1
      })).totalCount;
  
  
      if (totalRecords > exportLimit) {
          var exportApproval = true;
          (await $shell.Inventory.openInfoDialog(`Export limitation of ${exportLimit} exceeded`, `Excel will only export ${exportLimit} records`));
      } else {
          exportApproval = true;
      }
  
  
      if (exportApproval) {
          var recordsTaken: number = 0;
          var remainingRecords: number = Math.min(exportLimit, totalRecords);
          do {
              var response = await $datasources.Inventory.ds_inventory_lot_grid.get({
                  ownerId: $grid.inParams.ownerId,
                  projectId: $grid.inParams.projectId,
                  warehouseId: $grid.inParams.warehouseId,
                  materialIds: $grid.inParams.materialIds,
                  fullTextSearch: $grid.fullTextSearch,
                  take: Math.min(remainingRecords, topLimit),
                  skip: recordsTaken
              });
              allData = allData.concat(response.result);
              recordsTaken += response.result.length
              remainingRecords -= response.result.length
  
  
          } while (remainingRecords > 0 && response.result.length > 0)
  
          // Function to chunk an array into smaller arrays of a specified size
          function chunkArray<T>(array: T[], chunkSize: number): T[][] {
              const chunks: T[][] = [];
              for (let i = 0; i < array.length; i += chunkSize) {
                  chunks.push(array.slice(i, i + chunkSize));
              }
              return chunks;
          }
  
          // Main function to get the aggregated results
          async function getAggregatedInventory(allData: any[], chunkSize: number) {
              const lotIds = [...new Set(allData.map(a => Number(a.LotId)))];
              const packagedIds = [...new Set(allData.map(a => Number(a.PackagedId)))];
              const warehouseIds = [...new Set(allData.map(a => Number(a.WarehouseId)))];
  
              // Split the arrays into smaller chunks
              const lotIdChunks = chunkArray(lotIds, chunkSize);
              const packagedIdChunks = chunkArray(packagedIds, chunkSize);
              const warehouseIdChunks = chunkArray(warehouseIds, chunkSize);
  
              let aggregatedResults: any[] = [];
  
              // Helper function to handle HTTP calls
              async function fetchChunk(lotIds: number[], packagedIds: number[], warehouseIds: number[]) {
                  const response = await $datasources.Inventory.ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_batched.get({
                      lotIds,
                      packagedIds,
                      warehouseIds
                  });
                  return response.result;
              }
  
              // Loop through the chunks and make HTTP calls
              for (let i = 0; i < lotIdChunks.length; i++) {
                  for (let j = 0; j < packagedIdChunks.length; j++) {
                      for (let k = 0; k < warehouseIdChunks.length; k++) {
                          const lotIdChunk = lotIdChunks[i];
                          const packagedIdChunk = packagedIdChunks[j];
                          const warehouseIdChunk = warehouseIdChunks[k];
  
                          const result = await fetchChunk(lotIdChunk, packagedIdChunk, warehouseIdChunk);
                          aggregatedResults = aggregatedResults.concat(result);
                      }
                  }
              }
  
              return aggregatedResults;
          }
  
  
  
          const chunkSize = 500; // Adjust chunk size as needed
          let lot_availability_totals: any[] = [];
  
          await getAggregatedInventory(allData, chunkSize)
              .then(result => {
                  lot_availability_totals = result;
              })
              .catch(error => {
                  console.error("Error fetching inventory:", error);
              });
  
  
          // const lotIds = [...new Set(allData.map(a => a.LotId))]
          // const packagedIds = [...new Set(allData.map(a => a.PackagedId))]
          // const warehouseIds = [...new Set(allData.map(a => a.WarehouseId))]
  
          // let lot_availability_totals = (await $datasources.Inventory.ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_batched.get({
          //     lotIds: lotIds,
          //     packagedIds: packagedIds,
          //     warehouseIds: warehouseIds
          // })).result;
  
          // Compile all data into JSON
          for (let record of allData) {
  
              const lot_availability = lot_availability_totals.find(lot => lot.LotId === record.LotId
                  && lot.Warehouse?.Id === record.WarehouseId && lot.PackagedId === record.PackagedId);
  
  
  
              transactions.push({
  
                  "Warehouse": record.Warehouse?.Name,
                  "Project": record.Lot?.Material?.Project?.LookupCode,
                  "Material": record.Lot?.Material?.LookupCode,
                  "Lot": record.Lot?.LookupCode,
                  "Total Amount": record.PackagedAmount,
                  "Available Amount": lot_availability?.TotalAvailableAmount,
                  "Packaging": record.Packaging?.ShortName,
                  "Gross Weight": record.GrossWeight,
                  "Weight Uom": record.WeightUom?.Short_name,
                  "Vendor Lot": record.Lot?.VendorLot?.LookupCode,
                  "Expiration Date": $utils.date.format(record.Lot?.VendorLot?.ExpirationDate, dateFormat),
                  "Manufacture Date": $utils.date.format(record.Lot?.VendorLot?.ManufactureDate, dateFormat),
                  "Received Date": $utils.date.format(record.Lot?.ReceiveDate, dateFormat),
                  "Material Description": record.Lot?.Material?.Description
              });
          }
          var ws = $utils.excel.json_to_sheet(transactions);
          $utils.excel.book_append_sheet(wb, ws, "InventoryExport");
      }
  
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      throw new Error(errorMessage);
  }
  
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: app_custom_inventory_lot_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const lotIds = ($grid.rows.map(r => r.entity?.LotId))
  const packagedIds = ($grid.rows.map(r => r.entity?.PackagedId))
  const warehouseIds = ($grid.rows.map(r => r.entity?.WarehouseId))
  
  let lot_availability = (await $datasources.Inventory.ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_batched.get({ lotIds: lotIds, packagedIds: packagedIds, warehouseIds: warehouseIds })).result;
  let inboundOrders = (await $datasources.Inventory.ds_get_lot_original_inbound_order_batched.get({ lotIds: lotIds })).result
  
  
  for (let row of $grid.rows) {
      for (let available of lot_availability) {
          if (available.LotId === row.entity.LotId && available.Warehouse.Id === row.entity.WarehouseId && available.PackagedId === row.entity.PackagedId) {
              row.cells.available_packaged_amount.displayControl.text = available.TotalAvailableAmount.toString();
          }
      }
      for (let order of inboundOrders){
          if(order.LotId === row.entity.LotId){
              row.cells.inbound_order.displayControl.text = order.order?.LookupCode;
          }
      }
  }
  
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'app_custom_inventory_lot_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
