import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Dynamics365BusinessCentral_flow_accounts_queryService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_delete_invoiceService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_get_compguidService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_initial_configService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_message_status_updateService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_new_invoice_lines_insertService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_new_invoices_header_autoService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_new_invoices_header_manualService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_flow_token_generateService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_post_invoice_flowService } from './Dynamics365BusinessCentral.flow.index';

import { $frontendTypes } from './Dynamics365BusinessCentral.frontend.types'

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Dynamics365BusinessCentral: Dynamics365BusinessCentral_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _flow_accounts_query: Dynamics365BusinessCentral_flow_accounts_queryService;
  public async flow_accounts_query(inParams: { transaction_id: string, invoice_id: number, inv_id: string, customer: string, lookup: string }): Promise< { transaction_id?: string, astatus?: string, errmessage?: string }> {
    if(!this._flow_accounts_query) {
      this._flow_accounts_query = this.injector.get(Dynamics365BusinessCentral_flow_accounts_queryService);
    }
    return this._flow_accounts_query.run(inParams);
  }
   
   

   
 
  private _flow_delete_invoice: Dynamics365BusinessCentral_flow_delete_invoiceService;
  public async flow_delete_invoice(inParams: { inv_id?: string }): Promise<void> {
    if(!this._flow_delete_invoice) {
      this._flow_delete_invoice = this.injector.get(Dynamics365BusinessCentral_flow_delete_invoiceService);
    }
    return this._flow_delete_invoice.run(inParams);
  }
   
   

   
 
  private _flow_get_compguid: Dynamics365BusinessCentral_flow_get_compguidService;
  public async flow_get_compguid(inParams: { displayName: string }): Promise< { name?: string, env?: string, url?: string }> {
    if(!this._flow_get_compguid) {
      this._flow_get_compguid = this.injector.get(Dynamics365BusinessCentral_flow_get_compguidService);
    }
    return this._flow_get_compguid.run(inParams);
  }
   
   

   
 
  private _flow_initial_config: Dynamics365BusinessCentral_flow_initial_configService;
  public async flow_initial_config(inParams: { client_id: string, client_secret: string, scope: string, tenantid: string, companyDisplayName: string, environment?: string, url?: string, cg?: string }): Promise< { tokens?: { AccessToken?: string, RefreshToken?: string, RealmId?: string, Skey?: string } }> {
    if(!this._flow_initial_config) {
      this._flow_initial_config = this.injector.get(Dynamics365BusinessCentral_flow_initial_configService);
    }
    return this._flow_initial_config.run(inParams);
  }
   
   

   
 
  private _flow_message_status_update: Dynamics365BusinessCentral_flow_message_status_updateService;
  public async flow_message_status_update(inParams: { m_id: string, inv_id: string }): Promise<void> {
    if(!this._flow_message_status_update) {
      this._flow_message_status_update = this.injector.get(Dynamics365BusinessCentral_flow_message_status_updateService);
    }
    return this._flow_message_status_update.run(inParams);
  }
   
   

   
 
  private _flow_new_invoice_lines_insert: Dynamics365BusinessCentral_flow_new_invoice_lines_insertService;
  public async flow_new_invoice_lines_insert(inParams: { transaction_id: string, invoice_lines: string, customerr: string, lookup: string, id: number, inv_id: string }): Promise< { transaction_id?: string, lstatus?: string, errmessage?: string }> {
    if(!this._flow_new_invoice_lines_insert) {
      this._flow_new_invoice_lines_insert = this.injector.get(Dynamics365BusinessCentral_flow_new_invoice_lines_insertService);
    }
    return this._flow_new_invoice_lines_insert.run(inParams);
  }
   
   

   
 
  private _flow_new_invoices_header_auto: Dynamics365BusinessCentral_flow_new_invoices_header_autoService;
  public async flow_new_invoices_header_auto(inParams: { retryInsert?: string, transaction_id?: string }): Promise< { transaction_id?: string, errmessage?: string }> {
    if(!this._flow_new_invoices_header_auto) {
      this._flow_new_invoices_header_auto = this.injector.get(Dynamics365BusinessCentral_flow_new_invoices_header_autoService);
    }
    return this._flow_new_invoices_header_auto.run(inParams);
  }
   
   

   
 
  private _flow_new_invoices_header_manual: Dynamics365BusinessCentral_flow_new_invoices_header_manualService;
  public async flow_new_invoices_header_manual(inParams: { Id: number, transaction_id?: string, retryInsert?: string }): Promise< { transaction_id?: string, errmessage?: string }> {
    if(!this._flow_new_invoices_header_manual) {
      this._flow_new_invoices_header_manual = this.injector.get(Dynamics365BusinessCentral_flow_new_invoices_header_manualService);
    }
    return this._flow_new_invoices_header_manual.run(inParams);
  }
   
   

   
 
  private _flow_token_generate: Dynamics365BusinessCentral_flow_token_generateService;
  public async flow_token_generate(inParams: { transaction_id?: string }): Promise< { tokens?: { AccessToken?: string }, transaction_id?: string, retryInsert?: string }> {
    if(!this._flow_token_generate) {
      this._flow_token_generate = this.injector.get(Dynamics365BusinessCentral_flow_token_generateService);
    }
    return this._flow_token_generate.run(inParams);
  }
   
   

   
 
  private _post_invoice_flow: Dynamics365BusinessCentral_post_invoice_flowService;
  public async post_invoice_flow(inParams: { id?: number, transaction_id?: string }): Promise< { result_err?: string, result_ok?: string }> {
    if(!this._post_invoice_flow) {
      this._post_invoice_flow = this.injector.get(Dynamics365BusinessCentral_post_invoice_flowService);
    }
    return this._post_invoice_flow.run(inParams);
  }
   
   

   
}
