import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_return_order_lines_gridComponent } from './FootPrintManager.return_order_lines_grid.component';
import { FootPrintManager_customer_return_shipping_containers_gridComponent } from './FootPrintManager.customer_return_shipping_containers_grid.component';
import { FootPrintManager_accessorial_tasks_gridComponent } from './FootPrintManager.accessorial_tasks_grid.component';
import { FootPrintManager_billing_records_gridComponent } from './FootPrintManager.billing_records_grid.component';
import { FootPrintManager_orderaddresses_gridComponent } from './FootPrintManager.orderaddresses_grid.component';
import { FootPrintManager_temperature_readings_gridComponent } from './FootPrintManager.temperature_readings_grid.component';
import { Returns_orderline_total_amount_widgetComponent } from './Returns.orderline_total_amount_widget.component';
import { Returns_orderline_total_received_widgetComponent } from './Returns.orderline_total_received_widget.component';
import { Returns_orderline_total_gross_received_widgetComponent } from './Returns.orderline_total_gross_received_widget.component';
import { Returns_order_classes_dd_singleComponent } from './Returns.order_classes_dd_single.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Carriers_carriers_ownerscarriers_dd_singleComponent } from './Carriers.carriers_ownerscarriers_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_return_order_lines_gridComponent),
    forwardRef(() => FootPrintManager_customer_return_shipping_containers_gridComponent),
    forwardRef(() => FootPrintManager_accessorial_tasks_gridComponent),
    forwardRef(() => FootPrintManager_billing_records_gridComponent),
    forwardRef(() => FootPrintManager_orderaddresses_gridComponent),
    forwardRef(() => FootPrintManager_temperature_readings_gridComponent),
    forwardRef(() => Returns_orderline_total_amount_widgetComponent),
    forwardRef(() => Returns_orderline_total_received_widgetComponent),
    forwardRef(() => Returns_orderline_total_gross_received_widgetComponent),
    forwardRef(() => Returns_order_classes_dd_singleComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Carriers_carriers_ownerscarriers_dd_singleComponent),
  ],
  selector: 'FootPrintManager-return_order_editor',
  templateUrl: './FootPrintManager.return_order_editor.component.html'
})
export class FootPrintManager_return_order_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { orderId: number } = { orderId: null };
  //#region Inputs
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { shipmentId?: number, billingAddressId?: number } = { };
  //#endregion
  entity: { Id?: number, AccountId?: number, BillingAddresId?: number, LookupCode?: string, Notes?: string, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredCarrierServiceTypeId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Project?: { OwnerId?: number }, OrderClass?: { Id?: number, Name?: string, OrderClassTypeId?: number }, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { Id?: number, ContainerIdentifier?: string, ConveyanceId?: number, ConveyenceReference?: string, SealId?: string, TrailerId?: string } }[], OrderLines?: { LineNumber?: number }[], Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, Tasks?: { Id?: number, Result?: string }[] };

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    order_class: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    rga_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vendor_reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expected_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    trailer_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    seal_number: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(null, null), true, 'Process', 'icon-ic_fluent_arrow_clockwise_dashes_20_regular', null)
    ),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    ),
      unreceive: new ToolModel(new ButtonModel('unreceive', new ButtonStyles(null, null), false, 'Unreceive all', 'icon-ic_fluent_archive_arrow_back_20_regular', null)
    ),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      appointment: new ToolModel(new ButtonModel('appointment', new ButtonStyles(null, null), false, 'Appointment', 'icon-ic_fluent_calendar_20_regular', null)
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      revert: new ToolModel(new ButtonModel('revert', new ButtonStyles(null, null), false, 'Revert', 'icon-ic_fluent_square_hint_arrow_back_20_regular', null)
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), true, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      on_print: new ToolModel(new ButtonModel('on_print', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_print_20_regular', null)
    ),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_attach_20_regular', null)
    ),
      separator4: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      surveys: new ToolModel(new ButtonModel('surveys', new ButtonStyles(null, null), false, 'Surveys', 'icon-ic_fluent_shifts_availability_20_regular', null)
    )
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Order code', true),
    order_class: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order_class'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Order class', true),
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Owner', true),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Project', true),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', true),
    order_status: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Order status', false),
    rga_number: new FieldModel(new TextBoxModel(this.formGroup.controls['rga_number'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'RGA reference', false),
    verify_rga: new FieldModel(new ButtonModel('', new ButtonStyles(['primary'], null), false, 'Verify', 'icon-ic_fluent_checkmark_20_regular', null)
, new ControlContainerStyles(null, null), '⠀', false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', false),
    ship_from_selection: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Ship from', 'icon-ic_fluent_video_person_20_regular', null)
, new ControlContainerStyles(null, null), '', false),
    ship_from_clear: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Clear', 'icon-ic_fluent_prohibited_20_regular', null)
, new ControlContainerStyles(null, null), '', false),
    ship_from: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false),
    vendor_reference: new FieldModel(new TextBoxModel(this.formGroup.controls['vendor_reference'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Vendor reference', false),
    expected_date: new FieldModel(new DateBoxModel(this.formGroup.controls['expected_date'] as DatexFormControl, null, false, 'l, LT', 'datetime', null)
, new ControlContainerStyles(null, null), 'Expected date', false),
    carrier: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Carrier', false),
    reference: new FieldModel(new TextBoxModel(this.formGroup.controls['reference'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Owner reference', false),
    trailer_number: new FieldModel(new TextBoxModel(this.formGroup.controls['trailer_number'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Trailer number', false),
    seal_number: new FieldModel(new TextBoxModel(this.formGroup.controls['seal_number'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Seal number', false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Order', false, true, true),
  reference_information: new FieldsetModel('Reference info', false, true, true),
  details: new FieldsetModel('Details', false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
      billing: new TabGroupModel(),
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      order_lines: new TabItemModel(
        this.rootTabGroup, 
        'Lines', 
        ),
      shipping_containers: new TabItemModel(
        this.rootTabGroup, 
        'Containers', 
        ),
      billing: new TabItemModel(
        this.rootTabGroup, 
        'Billing', 
        true,
        {
          accessorial_tasks: new TabItemModel(
          this.subTabGroups.billing, 
          'Accessorials', 
          ),
          billing_records: new TabItemModel(
          this.subTabGroups.billing, 
          'Billing Records', 
          ),
        }
        ),
      contacts: new TabItemModel(
        this.rootTabGroup, 
        'Contacts', 
        ),
      temperatures: new TabItemModel(
        this.rootTabGroup, 
        'Temperatures', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_order_lines_return_order_lines_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_order_lines_return_order_lines_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    get $tabs_order_lines_return_order_lines_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_order_lines_return_order_lines_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_shipping_containers_customer_return_shipping_containers_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_entityStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_warehouseId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.PreferredWarehouseId;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds: number[];
    get $tabs_billing_billing_records_billing_records_grid_inParams_orderIds(): number[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = [$editor.inParams.orderId];
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds, expr)) {
        this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds;
    }
  
    get $tabs_billing_billing_records_billing_records_grid_inParams_includeInvoiced(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds: number[];
    get $tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds(): number[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = [$editor.entity.ShipmentOrderLookups[0]?.ShipmentId];
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds, expr)) {
        this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds;
    }
  
    get $tabs_contacts_orderaddresses_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_temperatures_temperature_readings_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_order_lines', { read: FootPrintManager_return_order_lines_gridComponent }) $tabs_order_lines: FootPrintManager_return_order_lines_gridComponent;
      @ViewChild('$tabs_shipping_containers', { read: FootPrintManager_customer_return_shipping_containers_gridComponent }) $tabs_shipping_containers: FootPrintManager_customer_return_shipping_containers_gridComponent;
        @ViewChild('$tabs_billing_accessorial_tasks', { read: FootPrintManager_accessorial_tasks_gridComponent }) $tabs_billing_accessorial_tasks: FootPrintManager_accessorial_tasks_gridComponent;
        @ViewChild('$tabs_billing_billing_records', { read: FootPrintManager_billing_records_gridComponent }) $tabs_billing_billing_records: FootPrintManager_billing_records_gridComponent;
      @ViewChild('$tabs_contacts', { read: FootPrintManager_orderaddresses_gridComponent }) $tabs_contacts: FootPrintManager_orderaddresses_gridComponent;
      @ViewChild('$tabs_temperatures', { read: FootPrintManager_temperature_readings_gridComponent }) $tabs_temperatures: FootPrintManager_temperature_readings_gridComponent;
    //#endregion tabs children
    widgets = {
      orderline_total_amount_widget: new WidgetModel(),
      orderline_total_received_widget: new WidgetModel(),
      orderline_total_gross_received_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_orderline_total_amount_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_orderline_total_received_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_orderline_total_gross_received_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_orderline_total_amount_widget', { read:  Returns_orderline_total_amount_widgetComponent }) $widgets_orderline_total_amount_widget: Returns_orderline_total_amount_widgetComponent;
      @ViewChild('$widgets_orderline_total_received_widget', { read:  Returns_orderline_total_received_widgetComponent }) $widgets_orderline_total_received_widget: Returns_orderline_total_received_widgetComponent;
      @ViewChild('$widgets_orderline_total_gross_received_widget', { read:  Returns_orderline_total_gross_received_widgetComponent }) $widgets_orderline_total_gross_received_widget: Returns_orderline_total_gross_received_widgetComponent;
    //#endregion widgets children

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  get $fields_carrier_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.entity.Project.OwnerId;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    order_class: this.fields.order_class.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    rga_number: this.fields.rga_number.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
    vendor_reference: this.fields.vendor_reference.control.valueChanges
    ,
    expected_date: this.fields.expected_date.control.valueChanges
    ,
    carrier: this.fields.carrier.control.valueChanges
    ,
    reference: this.fields.reference.control.valueChanges
    ,
    trailer_number: this.fields.trailer_number.control.valueChanges
    ,
    seal_number: this.fields.seal_number.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.order_lines,
      this.tabs.shipping_containers,
      this.tabs.billing,
      this.tabs.contacts,
      this.tabs.temperatures,
    ]; 
    this.subTabGroups.billing.tabs = [
        this.tabs.billing.tabs.accessorial_tasks,
        this.tabs.billing.tabs.billing_records,
    ];    
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Return Order';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      orderId:  $editor.inParams.orderId 
    };

    const data = await this.datasources.Returns.ds_return_order_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.order_class.control as SelectBoxModel).reset($editor.entity.OrderClass.Id);
    (this.fields.owner.control as SelectBoxModel).reset($editor.entity.Project.OwnerId);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.ProjectId);
    (this.fields.warehouse.control as SelectBoxModel).reset($editor.entity.PreferredWarehouseId);
    (this.fields.order_status.control as TextModel).text = $editor.entity.Status.Name;
    (this.fields.rga_number.control as TextBoxModel).reset($editor.entity.VendorReference);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);
    (this.fields.vendor_reference.control as TextBoxModel).reset($editor.entity.VendorReference);
    (this.fields.expected_date.control as DateBoxModel).reset($editor.entity.RequestedDeliveryDate);
    (this.fields.carrier.control as SelectBoxModel).reset($editor.entity.PreferredCarrierId);
    (this.fields.reference.control as TextBoxModel).reset($editor.entity.OwnerReference);
    (this.fields.trailer_number.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.TrailerId);
    (this.fields.seal_number.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.SealId);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_orderline_total_amount_widget') {
      if (!isNil(this.$widgets_orderline_total_amount_widget) && !this.widgets.orderline_total_amount_widget.hidden) {
        this.$widgets_orderline_total_amount_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_orderline_total_received_widget') {
      if (!isNil(this.$widgets_orderline_total_received_widget) && !this.widgets.orderline_total_received_widget.hidden) {
        this.$widgets_orderline_total_received_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_orderline_total_gross_received_widget') {
      if (!isNil(this.$widgets_orderline_total_gross_received_widget) && !this.widgets.orderline_total_gross_received_widget.hidden) {
        this.$widgets_orderline_total_gross_received_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_order_lines') {
        if (!isNil(this.$tabs_order_lines) && !this.tabs.order_lines.hidden) {
          this.$tabs_order_lines.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_shipping_containers') {
        if (!isNil(this.$tabs_shipping_containers) && !this.tabs.shipping_containers.hidden) {
          this.$tabs_shipping_containers.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_billing_accessorial_tasks') {
        if (!isNil(this.$tabs_billing_accessorial_tasks) && !this.tabs.billing.tabs.accessorial_tasks.hidden) {
          this.$tabs_billing_accessorial_tasks.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_billing_billing_records') {
        if (!isNil(this.$tabs_billing_billing_records) && !this.tabs.billing.tabs.billing_records.hidden) {
          this.$tabs_billing_billing_records.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_contacts') {
        if (!isNil(this.$tabs_contacts) && !this.tabs.contacts.hidden) {
          this.$tabs_contacts.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_temperatures') {
        if (!isNil(this.$tabs_temperatures) && !this.tabs.temperatures.hidden) {
          this.$tabs_temperatures.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .order_class
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .rga_number
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .vendor_reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .expected_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .carrier
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .trailer_number
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .seal_number
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $editor: FootPrintManager_return_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      const orderState = (await $flows.Returns.get_return_orders_state_flow({
          orderIds: [$editor.entity.Id]
      })).orderStates.find(os => os.orderId === $editor.entity.Id);
  
      if ($utils.isDefined(orderState.failActionReasons.deleteReasons)) {
          throw new Error(`Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: orderState.failActionReasons.deleteReasons })).formattedValue}.`);
      }
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Delete Order', `Are you sure you want to delete order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
  
      if (confirm) {
          const result = await $flows.Returns.delete_return_order_flow({ orderId: $editor.entity.Id });
  
          if ($utils.isDefined(result.reasons)) {
              throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
          }
  
          $editor.close();
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error deleting order', targetError.message);
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_return_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      const orderState = (await $flows.Returns.get_return_orders_state_flow({
          orderIds: [$editor.entity.Id]
      })).orderStates.find(os => os.orderId === $editor.entity.Id);
  
      if ($utils.isDefined(orderState.failActionReasons.cancelReasons)) {
          throw new Error(`Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: orderState.failActionReasons.cancelReasons })).formattedValue}.`);
      }
      else {
          let confirm = await $shell.FootPrintManager.openConfirmationDialog(`Cancel return order ${$editor.entity.LookupCode}?`, null, 'Yes', 'No');
          if (confirm) {
  
              let result = await $flows.Returns.cancel_return_order_flow({ orderId: $editor.entity.Id });
  
              if ($utils.isDefined(result.reasons)) {
                  throw new Error(`Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue}.`);
              }
  
              await $shell.PurchaseOrders.openInfoDialog(`Order ${$editor.entity.LookupCode} has been cancelled`, null);
              await $editor.refresh();
          }
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error cancelling order', targetError.message);
  }
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $editor: FootPrintManager_return_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  try {
      const orderState = (await $flows.Returns.get_return_orders_state_flow({
          orderIds: [$editor.entity.Id]
      })).orderStates.find(os => os.orderId === $editor.entity.Id);
  
      if ($utils.isDefined(orderState.failActionReasons.completeReasons)) {
          throw new Error(`Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: orderState.failActionReasons.completeReasons })).formattedValue}.`);
      }
  
      const order = (await $datasources.PurchaseOrders.ds_get_order_totals_by_orderId.get({ orderId: $editor.entity.Id })).result;
      if ($utils.isDefined(order)) {
  
          var totalOrdered = $utils.isDefined(order.TotalOrdered) ? order.TotalOrdered : 0;
          var totalReceived = $utils.isDefined(order.TotalReceived) ? order.TotalReceived : 0;
          var totalCount = `Received ${totalReceived} of ${totalOrdered}. `
  
      }
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Complete Order', `Are you sure you want to complete order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          const result = await $flows.Returns.complete_return_order_flow({ orderId: $editor.entity.Id });
  
          if ($utils.isDefined(result.reasons)) {
              throw new Error(`Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue}`);
          }
  
          await $shell.FootPrintManager.openInfoDialog(`Order ${$editor.entity.LookupCode} has been completed successfully`, null);
          await $editor.refresh();
          await $editor.tabs.order_lines.activate();
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error completing order', targetError.message);
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_return_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Shipment created if not found in the on_init flow
  
  // Set editor title
  $editor.title = `Inbound Order ${$editor.entity.LookupCode}`;
  
  var shipFrom = '';
  
  const orderAddress = (await $flows.Addresses.get_address_single_string_by_orderId({ orderId: $editor.inParams.orderId }));
  if ($utils.isDefined(orderAddress)) {
      shipFrom = orderAddress.address;
  
  }
  
  // Set tabs based on order class
  if ($editor.entity.OrderClass.OrderClassTypeId === 3) {
      $editor.tabs.order_lines.hidden = true;
  }
  else {
      $editor.tabs.shipping_containers.hidden = true;
  }
  
  
  let orderState = (await $flows.Returns.get_return_orders_state_flow({ orderIds: [$editor.entity.Id]})).orderStates[0];
  
  setToolbarVisibility();
  
  // Only display ship from if it is not an auto generated account
  if ($utils.isDefined(shipFrom)) {
      if (!shipFrom.startsWith('ord-')) {
  
          $editor.fields.ship_from.hidden = false;
          $editor.fields.ship_from.control.text = shipFrom;
          $editor.fields.ship_from_clear.hidden = false;
  
      } else {
          $editor.fields.ship_from.hidden = true;
          $editor.fields.ship_from.control.text = '';
          $editor.fields.ship_from_clear.hidden = true;
      }
  }
  else {
      $editor.fields.ship_from.hidden = true;
      $editor.fields.ship_from.control.text = '';
      $editor.fields.ship_from_clear.hidden = true;
  }
  
  // Apply appointment text logic
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.inParams.orderId
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
      $editor.toolbar.appointment.control.label = (await $flows.DockAppointments.get_formatted_dock_appointment({
          dockAppointmentId: dockAppointments[0]?.DockAppointmentId,
      })).formattedDockAppointment;
  
  }
  else {
      // Reset the appointment label back to original
      $editor.toolbar.appointment.control.styles.resetStyle();
      $editor.toolbar.appointment.control.label = 'Appointment'
  }
  
  // Fetch shipments
  const shipments = (await $datasources.PurchaseOrders.ds_get_shipments_by_orderId.get({ orderId: $editor.entity.Id })).result;
  
  if (shipments.length > 1) {
      // close
      await $shell.PurchaseOrders.openErrorDialog('Invalid order', 'This order has more than one shipment. Editor will close');
      $editor.close();
      return;
  }
  
  else {
  
      $editor.fields.warehouse.control.readOnly = false;
      $editor.fields.warehouse.required = false;
  
      if (shipments.length === 1) {
          $editor.vars.shipmentId = shipments[0].ShipmentId;
          $editor.fields.warehouse.required = true;
  
      }
  
  
      const noWarehouse = $utils.isDefined($editor.fields.warehouse.control.value) === false;
  
      // created status
      if ($editor.entity.OrderStatusId === 1) {
          $editor.toolbar.cancel.control.readOnly = false;
          $editor.toolbar.on_delete.control.readOnly = false;
  
          $editor.toolbar.unreceive.control.readOnly = true;
  
          // Reset process button in case the editor is refreshed back to a created status
          $editor.toolbar.process.control.readOnly = false;
          $editor.toolbar.process.control.icon = "icon-ic_fluent_arrow_clockwise_dashes_20_regular";
  
  
          // disable process if no warehouse
          $editor.toolbar.process.control.readOnly = noWarehouse;
  
  
          // disable owner project if order lines exist
          const hasOrderLines = $editor.entity.OrderLines.length > 0;
          $editor.fields.owner.control.readOnly = hasOrderLines;
          $editor.fields.project.control.readOnly = hasOrderLines;
  
          $editor.fields.lookupcode.control.readOnly = true;
          $editor.fields.order_class.control.readOnly = true;
          $editor.fields.vendor_reference.control.readOnly = false;
          $editor.fields.carrier.control.readOnly = false;
          $editor.fields.expected_date.control.readOnly = false;
  
          // Widgets
          $editor.widgets.orderline_total_gross_received_widget.hidden = true;
          $editor.widgets.orderline_total_received_widget.hidden = true;
  
  
      } else if ($editor.entity.OrderStatusId === 2) {
          $editor.fields.lookupcode.control.readOnly = true;
          $editor.fields.order_class.control.readOnly = true;
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
          $editor.fields.vendor_reference.control.readOnly = false;
          $editor.fields.carrier.control.readOnly = false;
          $editor.fields.expected_date.control.readOnly = false;
  
          // Widgets
          $editor.widgets.orderline_total_gross_received_widget.hidden = false;
          $editor.widgets.orderline_total_received_widget.hidden = false;
  
          // Set custom status
          let order_state = await $flows.PurchaseOrders.get_purchase_order_state({
              orderId: $editor.entity.Id,
              shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId
          });
  
          if ($utils.isDefined(order_state.state)) {
              switch (order_state.state) {
                  case 2: {
                      $editor.fields.order_status.control.text = 'Ready to receive'
                      break;
                  }
                  case 3: {
                      $editor.fields.order_status.control.text = 'Receiving in progress'
                      break;
                  }
                  case 4: {
                      $editor.fields.order_status.control.text = 'Ready to complete'
                      break;
                  }
  
              }
  
          }
  
      } else {
          $editor.fields.lookupcode.control.readOnly = true;
          $editor.fields.order_class.control.readOnly = true;
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.vendor_reference.control.readOnly = true;
          $editor.fields.carrier.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
          $editor.fields.expected_date.control.readOnly = true;
  
          // Widgets
          $editor.widgets.orderline_total_gross_received_widget.hidden = false;
          $editor.widgets.orderline_total_received_widget.hidden = false;
      }
  }
  
  // Check if RGA is being utilized
  if ($editor.entity.OrderClass.Name.toUpperCase().trim() === 'INBOUND RMA') {
      let annotations = (await $datasources.Returns.ds_get_annotation_tasks_by_orderId.getList({ orderId: $editor.entity.Id, notes: 'RgaValidation', $top: 1 })).result;
  
      if ($utils.isDefined(annotations[0]?.Result) && $utils.parseXml(annotations[0].Result)?.Details?.IsValid?.toString().trim().toUpperCase() === 'TRUE') {
          /*** Case: Validated ***/
          // Text box
          $editor.fields.rga_number.control.styles.setStyle('background-color', '#c8ffc8')
      }
      else {
          /*** Case: Not Validated ***/
          // Text box
          $editor.fields.rga_number.control.styles.setStyle('background-color', '#ff060633')
      }
  
      $editor.fields.vendor_reference.hidden = true;
  
      $editor.fieldsets.reference_information.hidden = false;
  }
  else {
      $editor.fields.vendor_reference.hidden = false;
  
      $editor.fieldsets.reference_information.hidden = true;
  }
  
  // Call common flow to apply the role based permissions
  await $editor.apply_operations();
  
  
  /***********************************************
   * FUNCTIONS
  ************************************************/
  function setToolbarVisibility() {
      $editor.toolbar.appointment.hidden = false;
      $editor.toolbar.attachments.hidden = false;
      $editor.toolbar.cancel.hidden = $utils.isDefined(orderState.failActionReasons.cancelReasons);
      $editor.toolbar.complete.hidden = $utils.isDefined(orderState.failActionReasons.completeReasons);
      $editor.toolbar.on_delete.hidden = $utils.isDefined(orderState.failActionReasons.deleteReasons);
      $editor.toolbar.on_print.hidden = false;
      $editor.toolbar.process.hidden = $utils.isDefined(orderState.failActionReasons.processReasons);
      $editor.toolbar.revert.hidden = $utils.isDefined(orderState.failActionReasons.revertReasons);
      $editor.toolbar.surveys.hidden = false;
      $editor.toolbar.unreceive.hidden = $utils.isDefined(orderState.failActionReasons.unreceiveReasons);
      $editor.toolbar.separator1.hidden = false;
      $editor.toolbar.separator2.hidden = false;
      $editor.toolbar.separator3.hidden = false;
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_return_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  // Set Date/Time Formats
  $editor.fields.expected_date.control.format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  // Create shipment if none found
  const shipments = (await $datasources.Returns.ds_get_shipments_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  if (!$utils.isDefined(shipments)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      const order = (await $datasources.Returns.ds_get_order_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  
  
  
      if ($utils.isAllDefined(order, order.PreferredWarehouseId)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.inParams.orderId,
              "Shipment": {
                  "Id": nextShipmentId,
                  "LookupCode": order.LookupCode,
                  "CarrierId": order.PreferredCarrierId,
                  "ExpectedWarehouseId": order.PreferredWarehouseId,
                  "ExpectedDate": order.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 1
              }
          }
          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
      }
  }
  
  // Set warehouse on order if blank and shipment contains expected warehouse
  const order = (await $datasources.Returns.ds_get_order_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  if (!$utils.isDefined(order.PreferredWarehouseId) && $utils.isDefined(shipments[0]?.Shipment?.ExpectedWarehouseId)) {
  
      let payload: any = {};
  
      payload.PreferredWarehouseId = shipments[0]?.Shipment?.ExpectedWarehouseId;
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.inParams.orderId, entity: payload });
  }
  
  
  }
  on_owner_changed(event = null) {
    return this.on_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changedInternal(
    $editor: FootPrintManager_return_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.project.control.value = null;
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $editor: FootPrintManager_return_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.toolbar.process.control.readOnly = true;
  $editor.toolbar.cancel.control.readOnly = true;
  $editor.toolbar.on_delete.control.readOnly = true;
  
  try {
      const orderlines = (await $datasources.Returns.ds_get_order_lines_by_orderId.get({ orderId: $editor.entity.Id })).result;
      if (!$utils.isDefined(orderlines)) {
          throw new Error('Order must have at least one order line to process.')
      }
  
      const orderState = (await $flows.Returns.get_return_orders_state_flow({
          orderIds: [$editor.entity.Id]
      })).orderStates.find(os => os.orderId === $editor.entity.Id);
  
      if ($utils.isDefined(orderState.failActionReasons.processReasons)) {
          throw new Error(`Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: orderState.failActionReasons.processReasons })).formattedValue}.`);
      }
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Process Order', `Are you sure you want to process order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
  
  
          $editor.toolbar.process.control.readOnly = true;
          $editor.toolbar.process.control.icon = "icon datex-default-spinner";
          $editor.toolbar.cancel.control.readOnly = true;
          $editor.toolbar.on_delete.control.readOnly = true;
  
  
          const result = await $flows.Returns.process_return_order_flow({ orderId: $editor.entity.Id });
  
          if ($utils.isDefined(result.reasons)) {
              throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
          }
  
          await $shell.FootPrintManager.openInfoDialog(`Successfully processed order ${$editor.entity.LookupCode}`, null);
          await $editor.refresh();
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error processing order', targetError.message);
  }
  
  $editor.toolbar.process.control.readOnly = false;
  $editor.toolbar.cancel.control.readOnly = false;
  $editor.toolbar.on_delete.control.readOnly = false;
  
  $editor.toolbar.process.control.styles.resetStyle();
  $editor.toolbar.process.control.icon = "icon ms-Icon ms-Icon--Process";
  $editor.toolbar.process.control.label = 'Process';
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: FootPrintManager_return_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValues = $utils.isAllDefined(
      $editor.fields.lookupcode.control.value,
      $editor.fields.order_class.control.value,
      $editor.fields.project.control.value
  );
  
  if (allRequiredFieldHaveValues === false) {
      return;
  }
  
  
  var shipmentId = $editor.vars.shipmentId;
  
  
  let payload: any = {};
  if ($editor.fields.project.control.isChanged) {
      payload.ProjectId = $editor.fields.project.control.value;
  }
  if ($editor.fields.lookupcode.control.isChanged) {
      payload.LookupCode = $editor.fields.lookupcode.control.value;
  }
  if ($editor.fields.order_class.control.isChanged) {
      payload.OrderClassId = $editor.fields.order_class.control.value;
  }
  if ($editor.fields.reference.control.isChanged) {
      payload.OwnerReference = $editor.fields.reference.control.value;
  }
  if ($editor.fields.vendor_reference.control.isChanged) {
      payload.VendorReference = $editor.fields.vendor_reference.control.value;
  }
  if ($editor.fields.carrier.control.isChanged) {
      payload.PreferredCarrierId = $editor.fields.carrier.control.value;
  }
  if ($editor.fields.warehouse.control.isChanged) {
      if (!$utils.isDefined($editor.fields.warehouse.control.value)) {
          throw new Error('Please select a warehouse.')
      }
      else {
          payload.PreferredWarehouseId = $editor.fields.warehouse.control.value;
      }
  }
  if ($editor.fields.expected_date.control.isChanged) {
      payload.RequestedDeliveryDate = $editor.fields.expected_date.control.value;
  }
  if ($editor.fields.notes.control.isChanged) {
      payload.Notes = $editor.fields.notes.control.value;
  }
  
  if ($editor.fields.rga_number.control.isChanged) {
      payload.VendorReference = $editor.fields.rga_number.control.value;
  
      $editor.fields.rga_number.control.styles.reset();
  
      let taskId = $editor.entity.Tasks[0]?.Id;
  
      if ($utils.isDefined(taskId)) {
          await $flows.Utilities.crud_update_flow({ entitySet: 'Tasks', id: taskId, entity: { Result: null }});
      }
  }
  
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  
  // Create the shipment if it does not exist only if the order has a preferred warehouse of if the preferred warehouse is selected in the editor
  if (!$utils.isDefined(shipmentId)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      const order = (await $datasources.Returns.ds_get_order_by_orderId.get({ orderId: $editor.inParams.orderId })).result[0];
  
  
  
      if ($utils.isAllDefined(order, order.PreferredWarehouseId) || $utils.isAllDefined(order, $editor.fields.warehouse.control.value)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.inParams.orderId,
              "Shipment": {
                  "Id": nextShipmentId,
                  "LookupCode": order.LookupCode,
                  "CarrierId": order.PreferredCarrierId,
                  "ExpectedWarehouseId": $utils.isDefined($editor.fields.warehouse.control.value) ? $editor.fields.warehouse.control.value : order.PreferredWarehouseId,
                  "ExpectedDate": order.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 1
              }
          }
          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
  
          shipmentId = nextShipmentId;
          $editor.vars.shipmentId = nextShipmentId;
  
      }
  
  
  }
  
  if ($utils.isDefined(shipmentId)) {
  
      payload = {};
  
      if ($editor.fields.lookupcode.control.isChanged) {
          payload.LookupCode = $editor.fields.lookupcode.control.value;
      }
      if ($editor.fields.carrier.control.isChanged) {
          payload.CarrierId = $editor.fields.carrier.control.value;
      }
     if ($editor.fields.warehouse.control.isChanged) {
          if (!$utils.isDefined($editor.fields.warehouse.control.value)) {
              throw new Error('Please select a warehouse.')
          }
          else {
              payload.ExpectedWarehouseId = $editor.fields.warehouse.control.value;
          }
      }
      if ($editor.fields.expected_date.control.isChanged) {
          payload.ExpectedDate = $editor.fields.expected_date.control.value;
      }
      if ($editor.fields.seal_number.control.isChanged) {
          payload.SealId = $editor.fields.seal_number.control.value;
      }
      if ($editor.fields.trailer_number.control.isChanged) {
          payload.TrailerId = $editor.fields.trailer_number.control.value;
      }
      if ($editor.fields.notes.control.isChanged) {
          payload.Notes = $editor.fields.notes.control.value;
      }
      await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: shipmentId, entity: payload });
  }
  
  
  
  await $editor.refresh();
  }
  on_ship_from_clicked(event = null) {
    return this.on_ship_from_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_from_clickedInternal(
    $editor: FootPrintManager_return_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const dialogResult = await $shell.FootPrintManager.openownersaccounts_gridDialog({
      ownerId: $editor.entity.Project.OwnerId,
      typeIds: [1, 3, 4, 5], // Billing, Operations, Residential, Commerical
      accountTypeId: 1, // Vendor
      allowSelection: true
  });
  
  const userConfirmed = dialogResult.confirm;
  const accountId = dialogResult.accountId;
  const addressId = dialogResult.addressId;
  
  if (userConfirmed && $utils.isAllDefined(accountId, addressId)) {
      let payload: any = {};
  
      payload.AccountId = accountId;
      payload.BillingAddresId = addressId;
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  }
  // Always refresh as the user might have made some changes to an existing address
  await $editor.refresh();
  
  }
  on_revert_clicked(event = null) {
    return this.on_revert_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_revert_clickedInternal(
    $editor: FootPrintManager_return_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.toolbar.revert.control.readOnly = true;
  
  try {
      const orderState = (await $flows.Returns.get_return_orders_state_flow({
          orderIds: [$editor.entity.Id]
      })).orderStates.find(os => os.orderId === $editor.entity.Id);
  
      if ($utils.isDefined(orderState.failActionReasons.revertReasons)) {
          throw new Error(`Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: orderState.failActionReasons.revertReasons })).formattedValue}.`);
  
      }
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Revert Order', `Are you sure you want to bring the order back to the original created status  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
  
      if (confirm) {      
  
          const result = await $flows.Returns.revert_return_order_flow({
              orderId: $editor.entity.Id
          });
  
          if ($utils.isDefined(result.reasons)) {
              throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons})).formattedValue);
          }
  
          $editor.refresh();
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error reverting order', targetError.message);
  }
  
  $editor.toolbar.revert.control.readOnly = false;
  }
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $editor: FootPrintManager_return_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.inParams.orderId
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_editorDialog({
          dockAppointmentId: dockAppointments[0].DockAppointmentId
      })
  
  } else {
        // Check if the selected order is in a rejected status and throw an error.
      if ($editor.entity.OrderStatusId == 4096) {
  
          throw new Error('Selected order is currently in a rejected status, a dock appointment cannot be created against rejected orders.')
      }
  
      const nextAppointmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'DockAppointment' })).nextId;
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_creation_formDialog({
         
          warehouseId: [$editor.entity.PreferredWarehouseId],
          lookupcode: nextAppointmentId.toString(),
          scheduledArrival: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $editor.entity.RequestedDeliveryDate : $utils.date.now(),
          scheduledDeparture: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $utils.date.add(1, 'hour', $editor.entity.RequestedDeliveryDate) : $utils.date.add(1, 'hour', $utils.date.now()),
          typeId: 1,
          ownerId:  $editor.entity.Project.OwnerId,
          projectId: $editor.entity.ProjectId,
          carrierId: $editor.entity.PreferredCarrierId,
          orderId: $editor.inParams.orderId
      });
  
  }
  
  // Always refresh as the user might have made some changes to an existing address
  await $editor.refresh();
  
  }
  on_unreceive_clicked(event = null) {
    return this.on_unreceive_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_unreceive_clickedInternal(
    $editor: FootPrintManager_return_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      const orderState = (await $flows.Returns.get_return_orders_state_flow({
          orderIds: [$editor.entity.Id]
      })).orderStates.find(os => os.orderId === $editor.entity.Id);
  
      if ($utils.isDefined(orderState.failActionReasons.unreceiveReasons)) {
          throw new Error(`Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: orderState.failActionReasons.unreceiveReasons })).formattedValue}.`);
      }
  
      const dialogResult = await $shell.FootPrintManager.openunreceiving_all_options_formDialog({});
      var userConfirmed = dialogResult.confirm;
      var reasonCodeId = dialogResult.reasonCodeId;
  
      if (userConfirmed) {
  
          const result = await $flows.Returns.unreceive_return_order_flow({
              orderId: $editor.entity.Id
          });
  
          if ($utils.isDefined(result.reasons)) {
              throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
          }
  
          await $shell.FootPrintManager.openInfoDialog(`Successfully unreceived order ${$editor.entity.LookupCode}`, null);
          await $editor.refresh();
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error unreceiving order', targetError.message);
  }
  }
  on_ship_from_cleared(event = null) {
    return this.on_ship_from_clearedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_from_clearedInternal(
    $editor: FootPrintManager_return_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let payload: any = {};
  
  payload.AccountId = null;
  payload.BillingAddresId = null;
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  
  
  await $editor.refresh();
  
  }
  apply_operations(event = null) {
    return this.apply_operationsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async apply_operationsInternal(
    $editor: FootPrintManager_return_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //Get the temperature capture operation
  
  if (await $operations.FootPrintManager.Disable_Temperatures_Entry.isAuthorized()) {
      $editor.tabs.temperatures.hidden = true;
  };
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $editor: FootPrintManager_return_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openentity_attachments_gridDialog({ entityType: 'Order', entityKeys: [{ name: 'Id', value: $editor.entity.Id }]});
  }
  on_verify_rga_clicked(event = null) {
    return this.on_verify_rga_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_verify_rga_clickedInternal(
    $editor: FootPrintManager_return_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Execute customizable flow to verify RGA number
  let result = await $flows.Returns.validate_rga_number_flow({ rgaNumber: $editor.fields.rga_number.control.value, orderId: $editor.entity.Id })
  
  if (!$utils.isDefined(result.reasons)) {
      // Text box
      $editor.fields.rga_number.control.styles.setStyle('background-color', '#c8ffc8');
  }
  else {
      $editor.fields.rga_number.control.styles.setStyle('background-color', '#ff060633');
      await $shell.FootPrintManager.openInfoDialog('Invalid RGA number', (await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
  }
  
  }
  on_surveys_clicked(event = null) {
    return this.on_surveys_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_surveys_clickedInternal(
    $editor: FootPrintManager_return_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  if ($utils.isDefined($editor.entity.ShipmentOrderLookups)) {
      await $shell.FootPrintManager.opensubmitted_surveys_gridDialog({
          entities: [{ type: 'Shipment', ids: [$editor.entity.ShipmentOrderLookups[0].ShipmentId] }],
          operationContextTypes: null,
          warehouseIds: null
      }, 'modal');
  }
  
  
  }
  //#endregion private flows
}
