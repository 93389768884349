<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="LineNumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.LineNumber.displayControl.styles.style"
          [ngClass]="row.cells.LineNumber.displayControl.styles.classes"
          [matTooltip]="row.cells.LineNumber.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.LineNumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="billingcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.billingcode.displayControl.styles.style"
          [ngClass]="row.cells.billingcode.displayControl.styles.classes"
          [matTooltip]="row.cells.billingcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.billingcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Quantity.displayControl.styles.style"
          [ngClass]="row.cells.Quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.Quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UnitCost">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.UnitCost.displayControl.styles.style"
          [ngClass]="row.cells.UnitCost.displayControl.styles.classes"
          [matTooltip]="row.cells.UnitCost.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.UnitCost.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UnitPrice">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.UnitPrice.displayControl.styles.style"
          [ngClass]="row.cells.UnitPrice.displayControl.styles.classes"
          [matTooltip]="row.cells.UnitPrice.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.UnitPrice.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LineCost">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.LineCost.displayControl.styles.style"
          [ngClass]="row.cells.LineCost.displayControl.styles.classes"
          [matTooltip]="row.cells.LineCost.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.LineCost.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LinePrice">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.LinePrice.displayControl.styles.style"
          [ngClass]="row.cells.LinePrice.displayControl.styles.classes"
          [matTooltip]="row.cells.LinePrice.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.LinePrice.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LineTotal">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.LineTotal.displayControl.styles.style"
          [ngClass]="row.cells.LineTotal.displayControl.styles.classes"
          [matTooltip]="row.cells.LineTotal.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.LineTotal.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="billingcodedescription">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.billingcodedescription.displayControl.styles.style"
          [ngClass]="row.cells.billingcodedescription.displayControl.styles.classes"
          [matTooltip]="row.cells.billingcodedescription.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.billingcodedescription.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Notes.displayControl.styles.style"
          [ngClass]="row.cells.Notes.displayControl.styles.classes"
          [matTooltip]="row.cells.Notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
