<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-date_from" *ngIf="!filters.date_from.hidden" 
            class="field-container standard {{filters.date_from.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.date_from.styles.style"
            [ngClass]="filters.date_from.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.date_from.label + (filters.date_from.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.date_from.label}}<span *ngIf="filters.date_from.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_from"
            [format]="filters.date_from.control.format"
            [mode]="filters.date_from.control.mode"
            [ngStyle]="filters.date_from.control.styles.style"
            [ngClass]="filters.date_from.control.styles.classes"
            [tooltip]="filters.date_from.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.date_from.invalid">
          <ng-container *ngFor="let error of filters.date_from.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_to" *ngIf="!filters.date_to.hidden" 
            class="field-container standard {{filters.date_to.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.date_to.styles.style"
            [ngClass]="filters.date_to.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.date_to.label + (filters.date_to.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.date_to.label}}<span *ngIf="filters.date_to.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_to"
            [format]="filters.date_to.control.format"
            [mode]="filters.date_to.control.mode"
            [ngStyle]="filters.date_to.control.styles.style"
            [ngClass]="filters.date_to.control.styles.classes"
            [tooltip]="filters.date_to.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.date_to.invalid">
          <ng-container *ngFor="let error of filters.date_to.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-type" *ngIf="!filters.type.hidden" 
            class="field-container standard {{filters.type.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.type.styles.style"
            [ngClass]="filters.type.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.type.label + (filters.type.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.type.label}}<span *ngIf="filters.type.required" class="required-asterisk">*</span>
          </label>
        </div>
        <input data-cy="textBox" formControlName="type"
                matInput
                autocomplete="off"
                spellcheck="false"
                class="datex-textbox {{filters.type.control.readOnly ? 'readonly ' : ''}}"
                placeholder="{{filters.type.control.placeholder}}"
                [ngStyle]="filters.type.control.styles.style"
                [ngClass]="filters.type.control.styles.classes"
                [matTooltip]="filters.type.control.tooltip"
                matTooltipClass="datex-control-tooltip"> 
        <ng-container *ngIf="filters.type.invalid">
          <ng-container *ngFor="let error of filters.type.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="value1">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.value1.displayControl.styles.style"
          [ngClass]="row.cells.value1.displayControl.styles.classes"
          [matTooltip]="row.cells.value1.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.value1.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="value2">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.value2.displayControl.styles.style"
          [ngClass]="row.cells.value2.displayControl.styles.classes"
          [matTooltip]="row.cells.value2.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.value2.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="value3">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.value3.displayControl.styles.style"
          [ngClass]="row.cells.value3.displayControl.styles.classes"
          [matTooltip]="row.cells.value3.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.value3.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="value4">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.value4.displayControl.styles.style"
          [ngClass]="row.cells.value4.displayControl.styles.classes"
          [matTooltip]="row.cells.value4.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.value4.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="value5">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.value5.displayControl.styles.style"
          [ngClass]="row.cells.value5.displayControl.styles.classes"
          [matTooltip]="row.cells.value5.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.value5.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.type.displayControl.styles.style"
          [ngClass]="row.cells.type.displayControl.styles.classes"
          [matTooltip]="row.cells.type.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.date.displayControl.styles.style"
          [ngClass]="row.cells.date.displayControl.styles.classes"
          [matTooltip]="row.cells.date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
