import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles
} from './models/control';
import { Styles, ControlContainerStyles, CardStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { DatexFormControl } from './models/datex-form-control';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Dynamics365BusinessCentral_ShellService } from './Dynamics365BusinessCentral.shell.service';
import { Dynamics365BusinessCentral_OperationService } from './Dynamics365BusinessCentral.operation.service';
import { Dynamics365BusinessCentral_DatasourceService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_FlowService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_ReportService } from './Dynamics365BusinessCentral.report.index';
import { Dynamics365BusinessCentral_LocalizationService } from './Dynamics365BusinessCentral.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Dynamics365BusinessCentral.frontend.types'
import { $frontendTypes as $types} from './Dynamics365BusinessCentral.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Dynamics365BusinessCentral-card',
  templateUrl: './Dynamics365BusinessCentral.card.component.html'
})
export class Dynamics365BusinessCentral_cardComponent extends BaseComponent implements OnInit, OnDestroy {
  styles = new CardStyles(null);


  //#region Outputs
  //#endregion
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  @Output()
  $refreshEvent = new EventEmitter();


  formGroupContent: FormGroup = new FormGroup({
    date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  formGroupFooter: FormGroup = new FormGroup({
  });

  content = {
    fields: {
        date: new FieldModel(new DateBoxModel(this.formGroupContent.controls['date'] as DatexFormControl, null, false, 'MM/DD/YYYY', 'date', null)
, new ControlContainerStyles(['status-a'], null), 'Label of newField1', false),
    },
    fieldsets: {
  newGroup1: new FieldsetModel('Label of newGroup1', false, false, true),
}
  }

  footer = {
    fields: {
    },
    fieldsets: {
  newGroup1: new FieldsetModel('Label of newGroup1', false, false, true),
}
  }

  
  //#region content fields inParams
  //#endregion content fields inParams

  //#region footer fields inParams
  //#endregion footer fields inParams








  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Dynamics365BusinessCentral_ShellService,
private datasources: Dynamics365BusinessCentral_DatasourceService,
private flows: Dynamics365BusinessCentral_FlowService,
private reports: Dynamics365BusinessCentral_ReportService,
private localization: Dynamics365BusinessCentral_LocalizationService,
private operations: Dynamics365BusinessCentral_OperationService,
private logger: CleanupLoggerService,
) {
    super(); 
    this.$subscribeFormControlValueChanges();

  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {

    const $card = this;
    const $utils = this.utils;

    (this.content.fields.date.control as DateBoxModel).reset($card.content.fields.date.control.value);



    this.initialized = true;

  }

  private $subscribeFormControlValueChanges() {

  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);

    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }


}
