import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';
import { Addresses_FlowService } from './Addresses.flow.index';
import { Carriers_FlowService } from './Carriers.flow.index';
import { PrintNode_FlowService } from './PrintNode.flow.index';
import { PalletTransactions_FlowService } from './PalletTransactions.flow.index';
import { Lots_FlowService } from './Lots.flow.index';
import { Owners_FlowService } from './Owners.flow.index';
import { Discussions_FlowService } from './Discussions.flow.index';
import { ExcelOrderImport_FlowService } from './ExcelOrderImport.flow.index';
import { Document360_FlowService } from './Document360.flow.index';
import { Usersnap_FlowService } from './Usersnap.flow.index';
import { Locations_FlowService } from './Locations.flow.index';
import { Ultraship_FlowService } from './Ultraship.flow.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { PurchaseOrders_FlowService } from './PurchaseOrders.flow.index';
import { Inventory_FlowService } from './Inventory.flow.index';
import { ExcelInventoryImport_FlowService } from './ExcelInventoryImport.flow.index';
import { Dynamics365BusinessCentral_FlowService } from './Dynamics365BusinessCentral.flow.index';
import { DockAppointments_FlowService } from './DockAppointments.flow.index';
import { Materials_FlowService } from './Materials.flow.index';
import { Waves_FlowService } from './Waves.flow.index';
import { SalesOrders_FlowService } from './SalesOrders.flow.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { Reports_FlowService } from './Reports.flow.index';

import { app_create_invoice_flow_formService } from './app.flow.index';
import { app_custom_get_label_reportService } from './app.flow.index';
import { app_custom_print_labelsService } from './app.flow.index';
import { app_custom_print_request_flowService } from './app.flow.index';
import { app_freight_billing_process_ship_delivery_alert_flowService } from './app.flow.index';
import { app_freight_billing_ship_delivery_validateService } from './app.flow.index';

import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
    this.Addresses = this.injector.get(Addresses_FlowService);
    this.Carriers = this.injector.get(Carriers_FlowService);
    this.PrintNode = this.injector.get(PrintNode_FlowService);
    this.PalletTransactions = this.injector.get(PalletTransactions_FlowService);
    this.Lots = this.injector.get(Lots_FlowService);
    this.Owners = this.injector.get(Owners_FlowService);
    this.Discussions = this.injector.get(Discussions_FlowService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_FlowService);
    this.Document360 = this.injector.get(Document360_FlowService);
    this.Usersnap = this.injector.get(Usersnap_FlowService);
    this.Locations = this.injector.get(Locations_FlowService);
    this.Ultraship = this.injector.get(Ultraship_FlowService);
    this.Notifications = this.injector.get(Notifications_FlowService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_FlowService);
    this.Inventory = this.injector.get(Inventory_FlowService);
    this.ExcelInventoryImport = this.injector.get(ExcelInventoryImport_FlowService);
    this.Dynamics365BusinessCentral = this.injector.get(Dynamics365BusinessCentral_FlowService);
    this.DockAppointments = this.injector.get(DockAppointments_FlowService);
    this.Materials = this.injector.get(Materials_FlowService);
    this.Waves = this.injector.get(Waves_FlowService);
    this.SalesOrders = this.injector.get(SalesOrders_FlowService);
    this.Invoices = this.injector.get(Invoices_FlowService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_FlowService);
    this.FootPrintManager = this.injector.get(FootPrintManager_FlowService);
    this.Reports = this.injector.get(Reports_FlowService);
  }

    public Utilities: Utilities_FlowService;
    public Addresses: Addresses_FlowService;
    public Carriers: Carriers_FlowService;
    public PrintNode: PrintNode_FlowService;
    public PalletTransactions: PalletTransactions_FlowService;
    public Lots: Lots_FlowService;
    public Owners: Owners_FlowService;
    public Discussions: Discussions_FlowService;
    public ExcelOrderImport: ExcelOrderImport_FlowService;
    public Document360: Document360_FlowService;
    public Usersnap: Usersnap_FlowService;
    public Locations: Locations_FlowService;
    public Ultraship: Ultraship_FlowService;
    public Notifications: Notifications_FlowService;
    public PurchaseOrders: PurchaseOrders_FlowService;
    public Inventory: Inventory_FlowService;
    public ExcelInventoryImport: ExcelInventoryImport_FlowService;
    public Dynamics365BusinessCentral: Dynamics365BusinessCentral_FlowService;
    public DockAppointments: DockAppointments_FlowService;
    public Materials: Materials_FlowService;
    public Waves: Waves_FlowService;
    public SalesOrders: SalesOrders_FlowService;
    public Invoices: Invoices_FlowService;
    public FootPrintApiManager: FootPrintApiManager_FlowService;
    public FootPrintManager: FootPrintManager_FlowService;
    public Reports: Reports_FlowService;
  public app: app_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _create_invoice_flow_form: app_create_invoice_flow_formService;
  public async create_invoice_flow_form(inParams: { projectId: number, typeId?: number, invoiceDate?: string, dueDate?: string, referenceCode?: string, invoiceTermId?: number, currencyId?: number, notes?: string }): Promise< { reason?: string, invoiceId?: number }> {
    if(!this._create_invoice_flow_form) {
      this._create_invoice_flow_form = this.injector.get(app_create_invoice_flow_formService);
    }
    return this._create_invoice_flow_form.run(inParams);
  }
   
   

   
 
  private _custom_get_label_report: app_custom_get_label_reportService;
  public async custom_get_label_report(inParams: { orderId?: number, shipmentId?: number }): Promise< { reportName?: string }> {
    if(!this._custom_get_label_report) {
      this._custom_get_label_report = this.injector.get(app_custom_get_label_reportService);
    }
    return this._custom_get_label_report.run(inParams);
  }
   
   

   
 
  private _custom_print_labels: app_custom_print_labelsService;
  public async custom_print_labels(inParams: { order_id?: number, shipment_id?: number, order_line_number?: number, copies?: number, printer_name: string, report_name: string }): Promise<void> {
    if(!this._custom_print_labels) {
      this._custom_print_labels = this.injector.get(app_custom_print_labelsService);
    }
    return this._custom_print_labels.run(inParams);
  }
   
   

   
 
  private _custom_print_request_flow: app_custom_print_request_flowService;
  public async custom_print_request_flow(inParams: { printerId: number, content: string, contentType?: string, rotate?: number }): Promise< { outputDetails?: string, printJobId?: number }> {
    if(!this._custom_print_request_flow) {
      this._custom_print_request_flow = this.injector.get(app_custom_print_request_flowService);
    }
    return this._custom_print_request_flow.run(inParams);
  }
   
   

   
 
  private _freight_billing_process_ship_delivery_alert_flow: app_freight_billing_process_ship_delivery_alert_flowService;
  public async freight_billing_process_ship_delivery_alert_flow(inParams: { order_id: number }): Promise< { order_details?: any, trace?: any, results?: any }> {
    if(!this._freight_billing_process_ship_delivery_alert_flow) {
      this._freight_billing_process_ship_delivery_alert_flow = this.injector.get(app_freight_billing_process_ship_delivery_alert_flowService);
    }
    return this._freight_billing_process_ship_delivery_alert_flow.run(inParams);
  }
   
   

   
 
  private _freight_billing_ship_delivery_validate: app_freight_billing_ship_delivery_validateService;
  public async freight_billing_ship_delivery_validate(inParams: { order_id: number, expected_date?: string }): Promise< { results?: any }> {
    if(!this._freight_billing_ship_delivery_validate) {
      this._freight_billing_ship_delivery_validate = this.injector.get(app_freight_billing_ship_delivery_validateService);
    }
    return this._freight_billing_ship_delivery_validate.run(inParams);
  }
   
   

   
}
