<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="Save" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_save_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="discard" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_discard_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-sftpConfigurationGroup"
                     *ngIf="!fieldsets.sftpConfigurationGroup.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.sftpConfigurationGroup.collapsible }">
                    <div *ngIf="!fieldsets.sftpConfigurationGroup.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.sftpConfigurationGroup.toggle()">
                      <span class="fieldsetsTitle-text">FTP / SFTP Endpoint</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.sftpConfigurationGroup.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.sftpConfigurationGroup.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.sftpConfigurationGroup.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.sftpConfigurationGroup.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-sftp_endpoint" *ngIf="!fields.sftp_endpoint.hidden" 
                            class="field-container double {{fields.sftp_endpoint.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.sftp_endpoint.styles.style"
                            [ngClass]="fields.sftp_endpoint.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.sftp_endpoint.label + (fields.sftp_endpoint.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.sftp_endpoint.label}}<span *ngIf="fields.sftp_endpoint.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="sftp_endpoint"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.sftp_endpoint.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.sftp_endpoint.control.placeholder}}"
                                [ngStyle]="fields.sftp_endpoint.control.styles.style"
                                [ngClass]="fields.sftp_endpoint.control.styles.classes"
                                [matTooltip]="fields.sftp_endpoint.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.sftp_endpoint.invalid">
                          <ng-container *ngFor="let error of fields.sftp_endpoint.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-sftp_port" *ngIf="!fields.sftp_port.hidden" 
                            class="field-container double {{fields.sftp_port.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.sftp_port.styles.style"
                            [ngClass]="fields.sftp_port.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.sftp_port.label + (fields.sftp_port.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.sftp_port.label}}<span *ngIf="fields.sftp_port.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="sftp_port"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.sftp_port.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.sftp_port.control.placeholder}}"
                                [ngStyle]="fields.sftp_port.control.styles.style"
                                [ngClass]="fields.sftp_port.control.styles.classes"
                                [matTooltip]="fields.sftp_port.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.sftp_port.invalid">
                          <ng-container *ngFor="let error of fields.sftp_port.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-sftp_credentials"
                     *ngIf="!fieldsets.sftp_credentials.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.sftp_credentials.collapsible }">
                    <div *ngIf="!fieldsets.sftp_credentials.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.sftp_credentials.toggle()">
                      <span class="fieldsetsTitle-text">Credentials</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.sftp_credentials.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.sftp_credentials.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.sftp_credentials.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.sftp_credentials.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-sftp_username" *ngIf="!fields.sftp_username.hidden" 
                            class="field-container double {{fields.sftp_username.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.sftp_username.styles.style"
                            [ngClass]="fields.sftp_username.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.sftp_username.label + (fields.sftp_username.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.sftp_username.label}}<span *ngIf="fields.sftp_username.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="sftp_username"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.sftp_username.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.sftp_username.control.placeholder}}"
                                [ngStyle]="fields.sftp_username.control.styles.style"
                                [ngClass]="fields.sftp_username.control.styles.classes"
                                [matTooltip]="fields.sftp_username.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.sftp_username.invalid">
                          <ng-container *ngFor="let error of fields.sftp_username.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-sftp_password" *ngIf="!fields.sftp_password.hidden" 
                            class="field-container double {{fields.sftp_password.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.sftp_password.styles.style"
                            [ngClass]="fields.sftp_password.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.sftp_password.label + (fields.sftp_password.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.sftp_password.label}}<span *ngIf="fields.sftp_password.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="sftp_password"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.sftp_password.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.sftp_password.control.placeholder}}"
                                [ngStyle]="fields.sftp_password.control.styles.style"
                                [ngClass]="fields.sftp_password.control.styles.classes"
                                [matTooltip]="fields.sftp_password.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.sftp_password.invalid">
                          <ng-container *ngFor="let error of fields.sftp_password.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-directories"
                     *ngIf="!fieldsets.directories.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.directories.collapsible }">
                    <div *ngIf="!fieldsets.directories.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.directories.toggle()">
                      <span class="fieldsetsTitle-text">Directories</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.directories.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.directories.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.directories.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.directories.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-sftp_working_folder" *ngIf="!fields.sftp_working_folder.hidden" 
                            class="field-container double {{fields.sftp_working_folder.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.sftp_working_folder.styles.style"
                            [ngClass]="fields.sftp_working_folder.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.sftp_working_folder.label + (fields.sftp_working_folder.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.sftp_working_folder.label}}<span *ngIf="fields.sftp_working_folder.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="sftp_working_folder"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.sftp_working_folder.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.sftp_working_folder.control.placeholder}}"
                                [ngStyle]="fields.sftp_working_folder.control.styles.style"
                                [ngClass]="fields.sftp_working_folder.control.styles.classes"
                                [matTooltip]="fields.sftp_working_folder.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.sftp_working_folder.invalid">
                          <ng-container *ngFor="let error of fields.sftp_working_folder.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-sftp_archive_folder" *ngIf="!fields.sftp_archive_folder.hidden" 
                            class="field-container double {{fields.sftp_archive_folder.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.sftp_archive_folder.styles.style"
                            [ngClass]="fields.sftp_archive_folder.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.sftp_archive_folder.label + (fields.sftp_archive_folder.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.sftp_archive_folder.label}}<span *ngIf="fields.sftp_archive_folder.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="sftp_archive_folder"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.sftp_archive_folder.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.sftp_archive_folder.control.placeholder}}"
                                [ngStyle]="fields.sftp_archive_folder.control.styles.style"
                                [ngClass]="fields.sftp_archive_folder.control.styles.classes"
                                [matTooltip]="fields.sftp_archive_folder.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.sftp_archive_folder.invalid">
                          <ng-container *ngFor="let error of fields.sftp_archive_folder.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-sftp_error_folder" *ngIf="!fields.sftp_error_folder.hidden" 
                            class="field-container double {{fields.sftp_error_folder.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.sftp_error_folder.styles.style"
                            [ngClass]="fields.sftp_error_folder.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.sftp_error_folder.label + (fields.sftp_error_folder.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.sftp_error_folder.label}}<span *ngIf="fields.sftp_error_folder.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="sftp_error_folder"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.sftp_error_folder.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.sftp_error_folder.control.placeholder}}"
                                [ngStyle]="fields.sftp_error_folder.control.styles.style"
                                [ngClass]="fields.sftp_error_folder.control.styles.classes"
                                [matTooltip]="fields.sftp_error_folder.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.sftp_error_folder.invalid">
                          <ng-container *ngFor="let error of fields.sftp_error_folder.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

    </div>
  </div>
</div>